import { gql, useLazyQuery } from "@apollo/client";
import { Box, Grid, Tooltip } from "@mui/material";
import axios from "axios";
import { parsePhoneNumber } from "libphonenumber-js";
import React, { createContext, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useParams } from "react-router-dom";
import { IconButton, Uploader } from "rsuite";
import "../../src/uploader_css.css";
import MgpAccordion, { RedAsterisk } from "../components/global/MgpAccordion";
import { MgpAutoCompleteFreeSoloFormik } from "../components/global/MgpAutoCompleteFreeSoloFormik";
import { MgpDatePicker } from "../components/global/MgpDatePicker";
import { MgpMenuList } from "../components/global/MgpMenuList";
import { MgpMulticascader } from "../components/global/MgpMulticascader";
import { MgpTextLable } from "../components/global/MgpTextLable";
import { MgpToggleButtonGroup } from "../components/global/MgpToggleButtonGroup";
import { StorageHelper } from "../helpers";
import { ENV } from "../helpers/configs";
import { useComment, useEncounter, usePatient, useRbac } from "../hooks";
import { colors } from "../theme/colors";
import { CrfTextField } from "../components/global/CrfTextField";
import { DropDownCrf } from "../components/global/DropDownCrf";
import { DateCrf } from "../components/global/DateCrf";
import CancelIcon from "@mui/icons-material/Cancel";

const UtilContext = createContext();

export const UtilContextProvider = ({ children }) => {
  const [util, setUtil] = React.useState();

  // const [GetForm] = useLazyQuery(GET_FORM);

  const values = React.useMemo(() => ({ util, setUtil }), [util, setUtil]);

  return <UtilContext.Provider value={values}>{children}</UtilContext.Provider>;
};
export const UseUtilContext = () => {
  const context = useContext(UtilContext);
  return context || null; // Return null if context is undefined
};
const GET_FORM = gql`
  query Query {
    GetForms
  }
`;
export const generateDynamicComponent = (
  attribute,
  touched,
  errors,
  values,
  handleBlur,
  handleChange,
  setFieldValue,
  obj,
  viewMode,
  newVisit,
  commentComponent,
  setFieldTouched,
  commentData,
  setCommentData,
  setValues,
  encountedDataLatestStatus,
  edit,
  patientActive,
  edit_patientform,
  parentSection,
  textBoxRefs,
  reopen,
  setserotype,
  setnotemessage,
  encountedDatamain,
  setErrors,
  setTouched,
  submitErrors,
  submitTouched
) => {
  const { util, setUtil } = UseUtilContext();

  let {
    name,
    label,
    options,
    col: strCol = 6,
    disabled,
    error,
    dependent,
    required,
    fieldValue,
    allowed,
    subtracting,
    defaultValue,
    limit,
    fontsize,
    paddingT,
    startDate,
    endDate,
    disableFuture,
    disablePast,
    dependenValue,
    visitType,
    hidden,
    multiselect,
    checkboxRequired,
    uncheckedValues,
    monthly,
    aggrigation,
    dependentaggrigation,
    acceptedPathsList,
    numberformat,
    dependentreverse,
    dependentsdate,
    fieldValuedate,
    showtodayDate,
    reversedependent,
    dependentyear,
    dependents,
    fieldValues,
    removeDependence,
    checkfieldvalue,
    dependent1,
    dependent2,
    dependentMain,
    removeFiledsFromDropDown,
    optionsAfterRemoveing,
    removeoptionsdependent,
    parentName,
    parentType,
    restrictto,
    selectedcity,
    float,
    followuplable,
    filter,
    updatethevalue,
    enableEnter,
    dateFormat,
    onlyBasic,
    cascadehide,
    showonlybasline,
    checkpreviousencounter,
    serotype,
    section,
    AddAccordion,
    setlimit,
    autoFocus,
    startingnumber,
    removevalesfromoptions,
    optionsdependent,
    optionsfieldValue,
    mainTitle,
    setdefaultvalue,
    checkstartdate,
    removedynamicfields,
    name_change,
    allFieldsRequired,
  } = attribute;
  if (
    Object.keys(errors).length === 0 &&
    Object.keys(submitErrors).length > 0
  ) {
    setErrors({ ...submitErrors });
    setTouched({ ...submitTouched });
  }

  let hide = false;
  const col = Number(strCol);
  var splits = "";
  let value = values;
  const isBaselineVisit =
    values?.["basicVisit_typeofvisit"] === "Baseline Visit";
  const isFollowUpVisit =
    values?.["basicVisit_typeofvisit"] === "Follow Up Visit";
  const hasAsterisk = label?.endsWith("*");

  if (!values.hasOwnProperty(name)) {
    values[name] = "";
  }
  let encounterid;
  if (urlWindow.pathname.split("/")[3] === "newVisit") {
  } else {
    if (encountedDatamain) {
      if (Object.keys(encountedDatamain).length > 0) {
        if (encountedDatamain?.AddEncounter?.encounter_id) {
          encounterid = encountedDatamain?.AddEncounter?.encounter_id;
        } else {
          encounterid = encountedDatamain?.["encounter_id"];
        }
      }
    }
  }

  const handleFocus = (event, newValue) => {
    const groupName =
      event?.target?.getAttribute("data-toggle-group-name") ||
      event?.currentTarget?.getAttribute("data-toggle-group-name") ||
      event?.target?.name;

    if (newValue) {
      const existingsections = util.forms.register_patient.sections;
      const existingsectionss = existingsections?.filter(
        (e) => e.key !== "PregnancyHistory"
      );
      if (newValue === "Male") {
        StorageHelper.set("123", JSON.stringify(attribute.section));
        setUtil((prevUtil) => ({
          ...prevUtil,
          forms: {
            ...prevUtil.forms,
            register_patient: {
              ...prevUtil.forms.register_patient,
              sections: existingsectionss,
            },
          },
        }));
      } else {
        if (newValue === "Female") {
          const updatedSections = [...existingsectionss];

          updatedSections.splice(9, 0, {
            key: "PregnancyHistory",
            label: "Pregnancy History",
            multi_row: false,
          });
          StorageHelper.set("123", JSON.stringify(attribute.section));
          setUtil((prevUtil) => ({
            ...prevUtil,
            forms: {
              ...prevUtil.forms,
              register_patient: {
                ...prevUtil.forms.register_patient,
                sections: updatedSections,
              },
            },
          }));
        }
      }
    }
    if (
      (groupName === "PregnancyHistory_pregnancyhistoryafterdiagnosis" ||
        groupName === "PregnancyHistory_pregnancyafterdiagnosis") &&
      util?.forms?.register_patient?.attribute
    ) {
      const existingAttributes = util.forms.register_patient.attribute;
      const newAttributes = JSON.parse(JSON.stringify(existingAttributes));
      const attributeExists = (attributes, newAttribute) => {
        return attributes.some((attr) => attr.name === newAttribute.name);
      };
      const cleanedAttributes = newAttributes.filter(
        (attr) => !attr.name.match(/^PregnancyHistory_pregnancy\d+_ischecked$/)
      );
      const cleanedValues = { ...values };
      let finalvalue =
        cleanedValues["PregnancyHistory_pregnancyhistoryafterdiagnosis"] === ""
          ? 0
          : cleanedValues["PregnancyHistory_pregnancyhistoryafterdiagnosis"];

      for (
        let index = 1;
        index <=
        parseInt(
          cleanedValues["PregnancyHistory_pregnancyhistoryafterdiagnosis"]
        );
        index++
      ) {
        const pregnancyHistoryTemplate = {
          attribute_type: "DYNAMIC",
          name: `PregnancyHistory_pregnancy${index}_ischecked`,
          label: `Pregnancy ${index}`,
          error: `Pregnancy ${index} is required`,
          type: "Accordion",
          regex: "",
          required: false,
          default: "",
          data_type: "STRING",

          dependents: `PregnancyHistory_pregnancyhistoryafterdiagnosis`,
          fieldValues: `${index}`,
          removeDependence: [
            `PregnancyHistory_pregnancy_previouspregnancyoutcomes${index}`,
            `PregnancyHistory_pregnancy_immunosuppressivemedication${index}`,
          ],
          checkfieldvalue: ["", "false"],
          index: "",
          format: "boolean",
          hidden: true,
          prefix: "",
          suffix: "",
          mapping_table: "visit_type",
          options: [
            {
              attribute_type: "DYNAMIC",
              name: `PregnancyHistory_pregnancy_previouspregnancyoutcomes${index}`,
              label: `Previous Pregnancy Outcomes`,
              error: `Previous Pregnancy Outcomes ${index} is required`,
              type: "select",
              regex: "",
              required: true,
              default: "",
              data_type: "STRING",
              index: "",
              prefix: "",
              suffix: "",
              multiselect: false,
              dependentValidation: `PregnancyHistory_pregnancy${index}_ischecked`,
              fieldValueValidation: "true",
              format: "String",
              mapping_table: "visit_type",
              options: [
                "Full-term delivery",
                "Preterm delivery",
                "Miscarriage",
                "Stillbirth",
                "Elective abortion",
                "Other",
              ],
              mapping_type: "enum",
              encrypted: true,
              col: 6,
              padding: "0px",
              size: "small",
              section: "PregnancyHistory",
            },
            {
              attribute_type: "DYNAMIC",
              name: `PregnancyHistory_pregnancy_immunosuppressivemedication${index}`,
              label:
                "Use of immunosuppressive medication during previous pregnancy",
              error: `Use of immunosuppressive medication during previous pregnancy ${index} is required`,
              type: "toggle",
              regex: "",
              required: true,
              default: "",
              data_type: "STRING",
              index: "",
              prefix: "",
              suffix: "",
              format: "String",
              dependentValidation: `PregnancyHistory_pregnancy${index}_ischecked`,
              fieldValueValidation: "true",
              mapping_table: "visit_types",
              options: ["Yes", "No"],
              mapping_type: "enum",
              encrypted: true,
              col: 6,
              size: "small",
              section: "PregnancyHistory",
            },
            {
              attribute_type: "DYNAMIC",
              name: `PregnancyHistory_pregnancy_previouspregnancyoutcomesothers${index}`,
              label: "Please Specify",
              error: `Previous Pregnancy Outcomes ${index} is required`,
              type: "input",
              regex: "",
              required: false,
              default: "",
              dependent: `PregnancyHistory_pregnancy_previouspregnancyoutcomes${index}`,
              hidden: true,
              fieldValue: ["Other"],
              fieldValueValidation: ["Other"],
              data_type: "STRING",
              index: "",
              prefix: "",
              suffix: "",
              mapping_table: "",
              mapping_type: "enum",
              encrypted: true,
              col: "12",
              section: "PregnancyHistory",
            },
          ],
          mapping_type: "enum",
          encrypted: true,
          col: "12",
          section: "PregnancyHistory",
        };
        Object.keys(pregnancyHistoryTemplate).forEach((key) => {
          cleanedValues[key] = "";
        });

        pregnancyHistoryTemplate.options.forEach((option) => {
          cleanedValues[option.name] = "";
        });
        if (!attributeExists(cleanedAttributes, pregnancyHistoryTemplate)) {
          cleanedAttributes.push(pregnancyHistoryTemplate);
        }
      }

      if (
        values?.["PregnancyHistory_pregnancyhistoryafterdiagnosis"] === "" ||
        cleanedValues["PregnancyHistory_pregnancyhistoryafterdiagnosis"] ===
          null
      ) {
        Object.keys(values).forEach((key) => {
          if (
            key.match(/^PregnancyHistory_pregnancy\d+_ischecked$/) ||
            key.match(
              /^PregnancyHistory_pregnancy_previouspregnancyoutcomes\d+$/
            ) ||
            key.match(
              /^PregnancyHistory_pregnancy_immunosuppressivemedication\d+$/
            ) ||
            key.match(
              /^PregnancyHistory_pregnancy_previouspregnancyoutcomesothers\d+$/
            )
          ) {
            delete values[key];
            delete cleanedValues[key];
          }
        });
        if (encountedDatamain) {
          if (Object.keys(encountedDatamain).length > 0) {
            if (encountedDatamain?.AddEncounter?.encounter_id) {
              StorageHelper.set(
                encountedDatamain?.AddEncounter?.encounter_id,
                JSON.stringify(cleanedValues)
              );
            } else {
              StorageHelper.set(
                encountedDatamain?.["encounter_id"],
                JSON.stringify(cleanedValues)
              );
            }
          }
        }
        setValues((prevValues) => ({
          ...prevValues,
          ...cleanedValues,
        }));
      } else {
        if (
          existingAttributes.length !== cleanedAttributes.length &&
          values["PregnancyHistory_pregnancyhistoryafterdiagnosis"] !== ""
        ) {
          const maxIndexToRemove = parseInt(finalvalue);
          Object.keys(values).forEach((key) => {
            if (
              key.match(/^PregnancyHistory_pregnancy\d+_ischecked$/) ||
              key.match(
                /^PregnancyHistory_pregnancy_previouspregnancyoutcomes\d+$/
              ) ||
              key.match(
                /^PregnancyHistory_pregnancy_immunosuppressivemedication\d+$/
              ) ||
              key.match(
                /^PregnancyHistory_pregnancy_previouspregnancyoutcomesothers\d+$/
              )
            ) {
              const index = parseInt(key.match(/\d+/)[0]);
              if (index > maxIndexToRemove) {
                delete values[key];
                delete cleanedValues[key];
              }
            }
          });
          if (encountedDatamain) {
            if (Object.keys(encountedDatamain).length > 0) {
              if (encountedDatamain?.AddEncounter?.encounter_id) {
                StorageHelper.set(
                  encountedDatamain?.AddEncounter?.encounter_id,
                  JSON.stringify(cleanedValues)
                );
              } else {
                StorageHelper.set(
                  encountedDatamain?.["encounter_id"],
                  JSON.stringify(cleanedValues)
                );
              }
            }
          }
          setValues((prevValues) => ({
            ...prevValues,
            ...cleanedValues,
          }));
          StorageHelper.set("123", JSON.stringify(attribute.section));
          setUtil((prevUtil) => ({
            ...prevUtil,
            forms: {
              ...prevUtil.forms,
              register_patient: {
                ...prevUtil.forms.register_patient,
                attribute: cleanedAttributes,
              },
            },
          }));
        }
      }
    }
    if (
      (groupName === "Hospitalizations_hospitalizationsprimarilyduemg" ||
        groupName ===
          "Hospitalizations_hospitalizationsrelatedmyastheniagravis") &&
      util?.forms?.register_patient?.attribute
    ) {
      if (encountedDatamain) {
        if (Object.keys(encountedDatamain).length > 0) {
          if (encountedDatamain?.AddEncounter?.encounter_id) {
            StorageHelper.set(
              encountedDatamain?.AddEncounter?.encounter_id,
              JSON.stringify(values)
            );
          } else {
            StorageHelper.set(
              encountedDatamain?.["encounter_id"],
              JSON.stringify(values)
            );
          }
        }
      }
      const existingAttributes = util.forms.register_patient.attribute;
      const newAttributes = JSON.parse(JSON.stringify(existingAttributes));
      const attributeExists = (attributes, newAttribute) => {
        return attributes.some((attr) => attr.name === newAttribute.name);
      };
      const cleanedAttributes = newAttributes.filter(
        (attr) => !attr.name.match(/^Hospitalizations_\d+_ischecked$/)
      );
      const cleanedValues = { ...values };
      for (
        let index = 1;
        index <=
        parseInt(
          cleanedValues["Hospitalizations_hospitalizationsprimarilyduemg"]
        );
        index++
      ) {
        const hospitalizationTemplate = {
          attribute_type: "DYNAMIC",
          name: `Hospitalizations_${index}_ischecked`,
          label: `Hospitalization ${index} *`,
          error: `${index} is required`,
          type: "Accordion",
          regex: "",
          required: false,
          default: "",
          data_type: "STRING",
          dependents: `Hospitalizations_hospitalizationsprimarilyduemg`,
          fieldValues: `${index}`,
          removeDependence: [
            `Hospitalizations_specifyward${index}`,
            `Hospitalizations_Whatwasthelengthofeachstayindays${index}`,
            `Hospitalizations_mainreasonformghospitalization${index}`,
            `Hospitalizations_mainreasonformghospitalizationothers${index}`,
            `Hospitalizations_underlyingcause${index}`,
            `Hospitalizations_underlyingcauseothers${index}`,
          ],
          checkfieldvalue: ["", "false"],
          index: "",
          format: "boolean",
          hidden: true,
          prefix: "",
          suffix: "",
          mapping_table: "visit_type",
          options: [
            {
              attribute_type: "DYNAMIC",
              name: `Hospitalizations_Whatwasthelengthofeachstayindays${index}`,
              label: `What was the length of each stay in days`,
              error: `What was the length of each stay in days required`,
              type: "input",
              regex: "",
              required: false,
              default: "",
              numberformat: true,
              limit: 3,
              hidden: false,
              checkVisitType: "basicVisit_typeofvisit",
              onlyBasic: false,
              data_type: "STRING",
              index: "",
              prefix: "",
              suffix: "",
              mapping_table: "",
              mapping_type: "enum",
              encrypted: true,
              col: "6",
              section: "Hospitalizations",
            },
            {
              attribute_type: "DYNAMIC",
              name: `Hospitalizations_specifyward${index}`,
              label: `Please specify the ward *`,
              error: `Please specify the ward is required`,
              type: "toggle",
              hidden: true,
              regex: "",
              dependentValidation: `Hospitalizations_${index}_ischecked`,
              fieldValueValidation: "true",
              format: "String",
              required: true,
              default: "",
              data_type: "STRING",
              index: "",
              prefix: "",
              suffix: "",
              mapping_table: "visit_types",
              options: ["ICU", "General Ward"],
              mapping_type: "enum",
              encrypted: true,
              col: 6,
              section: "Hospitalizations",
            },
            {
              attribute_type: "DYNAMIC",
              name: `Hospitalizations_mainreasonformghospitalization${index}`,
              label: `For each MG-related hospitalization, what was the main reason? *`,
              error: `For each MG-related hospitalization, what was the main reason? is required`,
              type: "select",
              regex: "",
              required: false,
              default: "",
              data_type: "STRING",
              hidden: true,
              index: "",
              prefix: "",
              suffix: "",
              format: "String",
              multiselect: false,
              dependentValidation: `Hospitalizations_${index}_ischecked`,
              fieldValueValidation: "true",
              mapping_table: "visit_type",
              options: [
                "Myasthenic crisis (respiratory failure)",
                "Exacerbation of MG symptoms (muscle weakness, difficulty swallowing)",
                "Thymectomy surgery ",
                "Other",
              ],
              removeDependence: [`Hospitalizations_underlyingcause${index}`],
              checkfieldvalue: ["Thymectomy surgery ", "Other"],
              mapping_type: "enum",
              encrypted: true,
              col: 6,
              padding: "25px",
              section: "Hospitalizations",
            },
            {
              attribute_type: "DYNAMIC",
              name: `Hospitalizations_mainreasonformghospitalizationothers${index}`,
              label: `Please Specify *`,
              error: `MG-related hospitalization is required`,
              type: "input",
              regex: "",
              required: false,
              default: "",
              dependent: `Hospitalizations_mainreasonformghospitalization${index}`,
              hidden: true,
              dependentValidation: `Hospitalizations_mainreasonformghospitalization${index}`,
              fieldValue: ["Other"],
              fieldValueValidation: ["Other"],
              data_type: "STRING",
              index: "",
              prefix: "",
              suffix: "",
              mapping_table: "",
              mapping_type: "enum",
              encrypted: true,
              col: 6,
              section: "Hospitalizations",
            },
            {
              attribute_type: "DYNAMIC",
              name: `Hospitalizations_underlyingcause${index}`,
              label: `What was the Underlying cause? *`,
              error: `What was the Underlying cause? is required`,
              type: "select",
              hidden: true,
              regex: "",
              default: "",
              dependent: `Hospitalizations_mainreasonformghospitalization${index}`,
              fieldValue: [
                "Myasthenic crisis (respiratory failure)",
                "Exacerbation of MG symptoms (muscle weakness, difficulty swallowing)",
              ],
              dependentValidation: `Hospitalizations_mainreasonformghospitalization${index}`,
              fieldValueValidation: [
                "Myasthenic crisis (respiratory failure)",
                "Exacerbation of MG symptoms (muscle weakness, difficulty swallowing)",
              ],
              required: false,
              disabled: true,
              data_type: "STRING",
              index: "",
              prefix: "",
              suffix: "",
              format: "array",
              multiselect: true,
              mapping_table: "visit_type",
              options: [
                "Respiratory Infections",
                "Other Infections",
                "Cardiac Causes",
                "Medication Side Effects or Allergic Reactions",
                "Non-Compliance",
                "Due to Medication Tapering",
                "Other",
              ],
              mapping_type: "enum",
              encrypted: true,
              col: 6,
              padding: "25px",
              section: "Hospitalizations",
            },
            {
              attribute_type: "DYNAMIC",
              name: `Hospitalizations_underlyingcauseothers${index}`,
              label: `Please Specify *`,
              error: `MG-related hospitalization is required`,
              type: "input",
              regex: "",
              required: false,
              default: "",
              dependent: `Hospitalizations_underlyingcause${index}`,
              hidden: true,
              fieldValue: ["Other"],
              fieldValueValidation: ["Other"],
              data_type: "STRING",
              dependentValidation: `Hospitalizations_underlyingcause${index}`,
              index: "",
              prefix: "",
              suffix: "",
              mapping_table: "",
              mapping_type: "enum",
              encrypted: true,
              col: 6,
              section: "Hospitalizations",
            },
          ],
          mapping_type: "enum",
          encrypted: true,
          col: "10",
          section: "Hospitalizations",
        };
        Object.keys(hospitalizationTemplate).forEach((key) => {
          cleanedValues[key] = "";
        });

        hospitalizationTemplate.options.forEach((option) => {
          cleanedValues[option.name] = "";
        });
        if (!attributeExists(cleanedAttributes, hospitalizationTemplate)) {
          cleanedAttributes.push(hospitalizationTemplate);
        }
      }
      if (values["Hospitalizations_hospitalizationsprimarilyduemg"] === "") {
        Object.keys(values).forEach((key) => {
          if (
            key.match(/^Hospitalizations_\d+_ischecked$/) ||
            key.match(
              /^Hospitalizations_Whatwasthelengthofeachstayindays\d+$/
            ) ||
            key.match(/^Hospitalizations_specifyward\d+$/) ||
            key.match(/^Hospitalizations_mainreasonformghospitalization\d+$/) ||
            key.match(
              /^Hospitalizations_mainreasonformghospitalizationothers\d+$/
            ) ||
            key.match(/^Hospitalizations_underlyingcause\d+$/) ||
            key.match(/^Hospitalizations_underlyingcauseothers\d+$/)
          ) {
            delete values[key];
            delete cleanedValues[key];
          }
        });
        if (encountedDatamain) {
          if (Object.keys(encountedDatamain).length > 0) {
            if (encountedDatamain?.AddEncounter?.encounter_id) {
              StorageHelper.set(
                encountedDatamain?.AddEncounter?.encounter_id,
                JSON.stringify(cleanedValues)
              );
            } else {
              StorageHelper.set(
                encountedDatamain?.["encounter_id"],
                JSON.stringify(cleanedValues)
              );
            }
          }
        }
      } else {
        if (
          existingAttributes.length !== cleanedAttributes.length &&
          values["Hospitalizations_hospitalizationsprimarilyduemg"] !== ""
        ) {
          const maxIndexToRemove = parseInt(
            cleanedValues["Hospitalizations_hospitalizationsprimarilyduemg"]
          );
          Object.keys(values).forEach((key) => {
            if (
              key.match(/^Hospitalizations_\d+_ischecked$/) ||
              key.match(
                /^Hospitalizations_Whatwasthelengthofeachstayindays\d+$/
              ) ||
              key.match(/^Hospitalizations_specifyward\d+$/) ||
              key.match(
                /^Hospitalizations_mainreasonformghospitalization\d+$/
              ) ||
              key.match(
                /^Hospitalizations_mainreasonformghospitalizationothers\d+$/
              ) ||
              key.match(/^Hospitalizations_underlyingcause\d+$/) ||
              key.match(/^Hospitalizations_underlyingcauseothers\d+$/)
            ) {
              const index = parseInt(key.match(/\d+/)[0]);
              if (index > maxIndexToRemove) {
                delete values[key];
                delete cleanedValues[key];
              }
            }
          });
          if (encountedDatamain) {
            if (Object.keys(encountedDatamain).length > 0) {
              if (encountedDatamain?.AddEncounter?.encounter_id) {
                StorageHelper.set(
                  encountedDatamain?.AddEncounter?.encounter_id,
                  JSON.stringify(cleanedValues)
                );
              } else {
                StorageHelper.set(
                  encountedDatamain?.["encounter_id"],
                  JSON.stringify(cleanedValues)
                );
              }
            }
          }
          StorageHelper.set("123", JSON.stringify(attribute.section));
          setUtil((prevUtil) => ({
            ...prevUtil,
            forms: {
              ...prevUtil.forms,
              register_patient: {
                ...prevUtil.forms.register_patient,
                attribute: cleanedAttributes,
              },
            },
          }));
        }
      }
    }
  };
  switch (attribute.type) {
    case "cascade":
      if (
        (showonlybasline &&
          values?.["basicVisit_typeofvisit"] !== "Follow Up Visit") ||
        showonlybasline === undefined
      ) {
        if (dependentreverse) {
          if (
            typeof values[dependent] === "object" ||
            typeof values[dependent] === "undefined" ||
            values[dependent] === ""
          ) {
            // error = "";
            // touched = "";
            required = true;
            disabled = false;
            hide = false;
          } else {
            error = "";
            touched = "";
            required = false;
            disabled = true;
            hide = false;
          }
        }
        let mainoptions = [];
        if (removevalesfromoptions) {
          if (values?.[optionsdependent] !== optionsfieldValue) {
            let updatedOptions = [];

            for (let i = 0; i < options?.length; i++) {
              let children = [];
              for (let j = 0; j < options[i].children.length; j++) {
                let elem = options[i].children[j];
                if (elem.label !== "Pregnancy Planning") {
                  children.push(elem);
                }
              }
              if (children.length > 0) {
                updatedOptions.push({ ...options[i], children });
              }
            }

            mainoptions = updatedOptions;
          }
        }

        if (mainoptions.length === 0) {
          mainoptions = options;
        }

        return (
          <Grid item xs={col} md={col}>
            {mainoptions.length ? (
              <Box position="relative">
                <MgpMulticascader
                  name={name}
                  title={label}
                  splits={splits}
                  required={required}
                  data={JSON.parse(JSON.stringify([...mainoptions]))}
                  value={value}
                  values={values}
                  attribute={attribute}
                  touched={touched}
                  cascadehide={cascadehide}
                  handleChangefrom={handleChange}
                  errors={errors}
                  disabled={disabled}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  obj={obj}
                  setValues={setValues}
                  viewMode={viewMode}
                  uncheckedValues={uncheckedValues}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  commentData={commentData}
                  encountedDataLatestStatus={encountedDataLatestStatus}
                  edit={edit}
                  mainTitle={mainTitle}
                  followuplable={followuplable}
                />
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            ) : null}
          </Grid>
        );
      }

      break;
    case "phone":
      return (
        <Grid item md={col}>
          <Box position="relative">
            <PhoneInput
              style={{ width: "100%" }}
              inputProps={{
                style: {
                  borderColor:
                    touched[name] && errors[name] ? colors.ERROR : colors.GOLD,
                  width: "100%",
                  height: "41px",
                },
              }}
              id="outlined-required"
              label={label}
              disabled={
                encountedDataLatestStatus === 0 ||
                encountedDataLatestStatus === 4 ||
                encountedDataLatestStatus === 6
                  ? !edit
                    ? true
                    : disabled
                  : disabled || viewMode
                  ? true
                  : false
              }
              country={"sa"}
              type="text"
              name={name}
              fullWidth
              value={values?.[name]}
              error={Boolean(touched[name] && errors[name])}
              onChange={(value, country, e) => {
                setFieldValue("countryCode", country?.countryCode);
                setFieldValue(name, value);
              }}
            />

            <Box position="absolute" top={-28} right={-20}>
              {commentComponent}
            </Box>
          </Box>
        </Grid>
      );

    case "autoComplete":
      if (dependent && !dependenValue) {
        const sp = dependent.split(".");
        for (let i = 0; i < sp.length; i++) {
          value = value[sp[i]];
          if (!value) break;
        }
        if (fieldValue === value) {
          required = true;
          disabled = false;
          hide = false;
        } else if (
          value &&
          Array.isArray(fieldValue) &&
          fieldValue.some((elems) => value?.includes(elems))
        ) {
          required = true;
          disabled = false;
          hide = false;
        } else {
          error = "";
          touched = "";
          required = false;
          disabled = true;
          hide = hidden ? true : false;
        }
      }
      if (visitType === undefined) {
        return (
          <>
            {!hide && (
              <Grid item md={col}>
                {options.length ? (
                  <MgpAutoCompleteFreeSoloFormik
                    name={name}
                    title={label}
                    splits={splits}
                    required={required}
                    data={JSON.parse(JSON.stringify([...options]))}
                    value={value}
                    values={values}
                    touched={touched}
                    handleChange={handleChange}
                    errors={errors}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    obj={obj}
                    viewMode={viewMode}
                    uncheckedValues={uncheckedValues}
                    error={Boolean(touched[name] && errors[name])}
                    attribute={attribute}
                  />
                ) : null}
              </Grid>
            )}
          </>
        );
      }
      break;
    case "input":
      if (reversedependent) {
        value = values?.[dependent];
        if (fieldValue === "") {
          if (value) {
            disabled = false;
          }
        } else {
          if (
            values?.[reversedependent] === "" ||
            values?.[reversedependent] === null
          ) {
            disabled = false;
          } else if (values?.[reversedependent] === fieldValue) {
            disabled = true;
          }
        }
      } else {
        if (dependent && fieldValue !== "") {
          value = values[dependent];

          if (fieldValue === value) {
            required = true;
            disabled = false;
            hide = false;
          } else if (
            value &&
            Array.isArray(fieldValue) &&
            fieldValue.some((elems) => value?.includes(elems))
          ) {
            required = true;
            disabled = false;
            hide = false;
          } else {
            error = "";
            touched = "";
            required = false;
            disabled = true;
            hide = hidden ? true : false;
          }
        }
      }
      if (aggrigation === "3") {
        if (values?.[dependentaggrigation] === undefined) {
          // disabled = false;
        } else {
          if (dependentaggrigation === name) {
            if (
              typeof values?.[dependentaggrigation] === "string" ||
              values?.[dependentaggrigation] === "" ||
              values?.[dependentaggrigation] === null
            ) {
              disabled = false;
            } else {
              if (values?.[dependentaggrigation] > 0) {
                disabled = true;
              }
            }
          } else {
            if (
              typeof values?.[dependentaggrigation] === "number" ||
              values?.[dependentaggrigation] === "" ||
              values?.[dependentaggrigation] === null
            ) {
              disabled = false;
            } else {
              disabled = true;
            }
          }
        }
      }

      if (onlyBasic === undefined) {
        return !hide ? (
          <Grid item md={col}>
            <Box position="relative">
              <CrfTextField
                fullWidth
                error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                // helperText={touched[name] && errors[name]}
                size="small"
                attribute={attribute}
                allowed={allowed}
                limit={limit}
                label={label}
                subtracting={subtracting}
                name={name}
                reversedependent={reversedependent}
                aggrigation={aggrigation}
                dependentaggrigation={dependentaggrigation}
                value={value}
                dependent1={dependent1}
                dependent2={dependent2}
                values={values}
                encounterid={encounterid}
                dependentyear={dependentyear}
                dependent={dependent}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                variant="outlined"
                disabled={
                  acceptedPathsList?.includes(
                    urlWindow.pathname.split("/")[3]
                  ) ||
                  (acceptedPathsList?.includes(
                    urlWindow.pathname.split("/")[3]
                  ) &&
                    values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                    ? encountedDataLatestStatus === 0 ||
                      encountedDataLatestStatus === 4 ||
                      encountedDataLatestStatus === 6
                      ? false
                      : true
                    : disabled
                }
                edit_patientform={edit_patientform}
                numberformat={numberformat}
                viewMode={viewMode}
                splits={splits}
                attr={attribute}
                commentData={commentData}
                encountedDataLatestStatus={encountedDataLatestStatus}
                dependentMain={dependentMain}
                edit={edit}
                parentName={parentName}
                parentType={parentType}
                float={float}
                enableEnter={enableEnter}
                textBoxRefs={textBoxRefs}
                AddAccordion={AddAccordion}
                sectionName={attribute.section}
                handleFocus={handleFocus}
                setlimit={setlimit}
                autoFocus={autoFocus}
                startingnumber={startingnumber}
                setFieldTouched={setFieldTouched}
                checkstartdate={checkstartdate}
                allFieldsRequired={allFieldsRequired}
              />
              <Box position="absolute" top={-28} right={-20}>
                {commentComponent}
              </Box>
            </Box>
          </Grid>
        ) : null;
      }
      if (onlyBasic && isBaselineVisit) {
        return !hide ? (
          <Grid item md={col}>
            <Box position="relative">
              <CrfTextField
                fullWidth
                error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                // helperText={touched[name] && errors[name]}
                size="small"
                attribute={attribute}
                allowed={allowed}
                limit={limit}
                label={label}
                subtracting={subtracting}
                name={name}
                reversedependent={reversedependent}
                aggrigation={aggrigation}
                dependentaggrigation={dependentaggrigation}
                value={value}
                dependent1={dependent1}
                dependent2={dependent2}
                values={values}
                dependentyear={dependentyear}
                dependent={dependent}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                variant="outlined"
                encounterid={encounterid}
                disabled={
                  acceptedPathsList?.includes(
                    urlWindow.pathname.split("/")[3]
                  ) ||
                  (acceptedPathsList?.includes(
                    urlWindow.pathname.split("/")[3]
                  ) &&
                    values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                    ? encountedDataLatestStatus === 0 ||
                      encountedDataLatestStatus === 4 ||
                      encountedDataLatestStatus === 6
                      ? false
                      : true
                    : disabled
                }
                edit_patientform={edit_patientform}
                numberformat={numberformat}
                viewMode={viewMode}
                splits={splits}
                attr={attribute}
                commentData={commentData}
                encountedDataLatestStatus={encountedDataLatestStatus}
                dependentMain={dependentMain}
                edit={edit}
                parentName={parentName}
                parentType={parentType}
                float={float}
                enableEnter={enableEnter}
                textBoxRefs={textBoxRefs}
                AddAccordion={AddAccordion}
                sectionName={attribute.section}
                handleFocus={handleFocus}
                setlimit={setlimit}
                autoFocus={autoFocus}
                startingnumber={startingnumber}
                setFieldTouched={setFieldTouched}
                checkstartdate={checkstartdate}
                allFieldsRequired={allFieldsRequired}
              />
              <Box position="absolute" top={-28} right={-20}>
                {commentComponent}
              </Box>
            </Box>
          </Grid>
        ) : null;
      }
      if (!onlyBasic && isFollowUpVisit) {
        return !hide ? (
          <Grid item md={col}>
            <Box position="relative">
              <CrfTextField
                fullWidth
                error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                // helperText={touched[name] && errors[name]}
                size="small"
                attribute={attribute}
                allowed={allowed}
                limit={limit}
                label={label}
                subtracting={subtracting}
                name={name}
                reversedependent={reversedependent}
                aggrigation={aggrigation}
                dependentaggrigation={dependentaggrigation}
                value={value}
                dependent1={dependent1}
                dependent2={dependent2}
                values={values}
                encounterid={encounterid}
                dependentyear={dependentyear}
                dependent={dependent}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                handleBlur={handleBlur}
                variant="outlined"
                disabled={
                  acceptedPathsList?.includes(
                    urlWindow.pathname.split("/")[3]
                  ) ||
                  (acceptedPathsList?.includes(
                    urlWindow.pathname.split("/")[3]
                  ) &&
                    values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                    ? encountedDataLatestStatus === 0 ||
                      encountedDataLatestStatus === 4 ||
                      encountedDataLatestStatus === 6
                      ? false
                      : true
                    : disabled
                }
                edit_patientform={edit_patientform}
                numberformat={numberformat}
                viewMode={viewMode}
                splits={splits}
                attr={attribute}
                commentData={commentData}
                encountedDataLatestStatus={encountedDataLatestStatus}
                dependentMain={dependentMain}
                edit={edit}
                parentName={parentName}
                parentType={parentType}
                float={float}
                enableEnter={enableEnter}
                textBoxRefs={textBoxRefs}
                AddAccordion={AddAccordion}
                sectionName={attribute.section}
                handleFocus={handleFocus}
                setlimit={setlimit}
                autoFocus={autoFocus}
                startingnumber={startingnumber}
                setFieldTouched={setFieldTouched}
                checkstartdate={checkstartdate}
                allFieldsRequired={allFieldsRequired}
              />
              <Box position="absolute" top={-28} right={-20}>
                {commentComponent}
              </Box>
            </Box>
          </Grid>
        ) : null;
      }

      break;

    case "date":
      if (reversedependent) {
        value = values?.[dependentsdate];
        if (fieldValuedate === "") {
          if (value) {
            disabled = false;
          }
        } else {
          if (
            values?.[reversedependent] === "" ||
            values?.[reversedependent] === null
          ) {
            disabled = false;
          }
        }
      } else {
        if (dependentsdate && fieldValuedate !== "") {
          value = values[dependentsdate];

          if (fieldValuedate === value) {
            required = true;
            disabled = false;
            hide = false;
          } else if (
            value &&
            Array.isArray(fieldValuedate) &&
            fieldValuedate.every((elems) => value?.includes(elems))
          ) {
            required = true;
            disabled = false;
            hide = false;
          } else {
            error = "";
            touched = "";
            required = false;
            disabled = true;
            hide = hidden ? true : false;
          }
        }
      }

      return !hide ? (
        <Grid item md={col}>
          <Box position="relative">
            <DateCrf
              viewMode={viewMode}
              size="small"
              fullWidth
              disabled={
                acceptedPathsList?.includes(urlWindow.pathname.split("/")[3]) ||
                (acceptedPathsList &&
                  values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                  ? true
                  : disabled
              }
              sectionName={attribute.section}
              nameLast={splits[1]}
              validation={attribute.disable_date}
              disableFuture={disableFuture}
              disablePast={disablePast}
              onChange={setFieldValue}
              showtodayDate={showtodayDate}
              errors={Boolean(submitTouched?.[name] && submitErrors?.[name])}
              startDate={startDate}
              edit_patientform={edit_patientform}
              endDate={endDate}
              monthly={monthly}
              dependentyear={dependentyear}
              reversedependent={reversedependent}
              attribute={attribute}
              commentData={commentData}
              encountedDataLatestStatus={encountedDataLatestStatus}
              setFieldValue={setFieldValue}
              touched={touched}
              dependent={dependent}
              handleChange={handleChange}
              label={label}
              name={name}
              subtracting={subtracting}
              values={values}
              handleBlur={handleBlur}
              edit={edit}
              restrictto={restrictto}
              aggrigation={aggrigation}
              dependentMain={dependentMain}
              dependent1={dependent1}
              dateFormat={dateFormat}
              // onChange={handleChange}
              variant="outlined"
            />
            <Box position="absolute" top={-28} right={-20}>
              {commentComponent}
            </Box>
          </Box>
        </Grid>
      ) : null;
    case "toggle":
      if (
        (showonlybasline &&
          values?.["basicVisit_typeofvisit"] !== "Follow Up Visit") ||
        showonlybasline === undefined
      ) {
        if (dependentreverse) {
          if (
            typeof values[dependent] === "object" ||
            typeof values[dependent] === "undefined" ||
            values[dependent] === ""
          ) {
            error = "";
            touched = "";
            required = true;
            disabled = false;
            hide = false;
          } else {
            error = "";
            touched = "";
            required = false;
            disabled = true;
            hide = false;
          }
        } else {
          if (dependent && fieldValue !== "") {
            value = values[dependent];
            if (fieldValue === value) {
              required = true;
              disabled = false;
              hide = false;
            } else if (
              value &&
              Array.isArray(fieldValue) &&
              fieldValue.every((elems) => value?.includes(elems))
            ) {
              required = true;
              disabled = false;
              hide = false;
            } else {
              error = "";
              touched = "";
              required = false;
              disabled = true;
              hide = hidden ? true : false;
            }
          }
        }
        if (checkpreviousencounter) {
          hide = true;
        }

        if (
          name === "basicVisit_typeofvisit" &&
          urlWindow.pathname.split("/")[3] !== "newVisit"
        ) {
          if (values?.[name] === "") {
            setFieldValue("basicVisit_typeofvisit", "Baseline Visit");
          }
        }
        if (
          name === "basicVisit_typeofvisit" &&
          values["basicVisit_typeofvisit"] === "Baseline Visit"
        ) {
          disabled = true;
          options = options.filter((e) => e === "Baseline Visit");
        } else {
          options = options.filter((e) => e !== "Baseline Visit");
        }
        if (onlyBasic === undefined) {
          if (defaultValue) {
            return !hide ? (
              <Grid item md={col} sm={col}>
                <Box position="relative">
                  <MgpToggleButtonGroup
                    encountedDatamain={encountedDatamain}
                    section={attribute.section}
                    handleFocus={handleFocus}
                    setValues={setValues}
                    disabled={
                      acceptedPathsList?.includes(
                        urlWindow.pathname.split("/")[3]
                      ) ||
                      (acceptedPathsList &&
                        values?.["basicVisit_typeofvisit"] ===
                          "Follow Up Visit")
                        ? true
                        : disabled
                    }
                    viewMode={viewMode}
                    splits={splits}
                    edit_patientform={edit_patientform}
                    attr={attribute}
                    touched={touched}
                    errors={errors}
                    name={name}
                    attribute={attribute}
                    size="small"
                    label={label}
                    defaultValue={defaultValue}
                    dependent={dependent}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    values={values}
                    removeDependence={removeDependence}
                    checkfieldvalue={checkfieldvalue}
                    commentData={commentData}
                    encountedDataLatestStatus={encountedDataLatestStatus}
                    options={options}
                    required={required}
                    newVisit={newVisit}
                    edit={edit}
                    parentType={parentType}
                    parentName={parentName}
                    followuplable={followuplable}
                    removedynamicfields={removedynamicfields}
                  ></MgpToggleButtonGroup>

                  <Box position="absolute" top={0} right={-20}>
                    {commentComponent}
                  </Box>
                </Box>
              </Grid>
            ) : null;
          } else {
            return !hide ? (
              <Grid item md={col} sm={col}>
                <Box position="relative">
                  <MgpToggleButtonGroup
                    encountedDatamain={encountedDatamain}
                    disabled={
                      acceptedPathsList?.includes(
                        urlWindow.pathname.split("/")[3]
                      ) ||
                      (acceptedPathsList &&
                        values?.["basicVisit_typeofvisit"] ===
                          "Follow Up Visit")
                        ? true
                        : disabled
                    }
                    edit_patientform={edit_patientform}
                    viewMode={viewMode}
                    removeDependence={removeDependence}
                    checkfieldvalue={checkfieldvalue}
                    setFieldValue={setFieldValue}
                    splits={splits}
                    attr={attribute}
                    touched={touched}
                    attribute={attribute}
                    errors={errors}
                    name={name}
                    setValues={setValues}
                    size="small"
                    label={label}
                    defaultValue={defaultValue}
                    commentData={commentData}
                    encountedDataLatestStatus={encountedDataLatestStatus}
                    dependent={dependent}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    values={values}
                    options={options}
                    required={required}
                    newVisit={newVisit}
                    edit={edit}
                    parentType={parentType}
                    parentName={parentName}
                    followuplable={followuplable}
                    section={attribute.section}
                    handleFocus={handleFocus}
                    removedynamicfields={removedynamicfields}
                  ></MgpToggleButtonGroup>
                  <Box position="absolute" top={0} right={-20}>
                    {commentComponent}
                  </Box>
                </Box>
              </Grid>
            ) : null;
          }
        }
        if (!onlyBasic && isFollowUpVisit) {
          if (defaultValue) {
            return !hide ? (
              <Grid item md={col} sm={col}>
                <Box position="relative">
                  <MgpToggleButtonGroup
                    encountedDatamain={encountedDatamain}
                    disabled={
                      acceptedPathsList?.includes(
                        urlWindow.pathname.split("/")[3]
                      ) ||
                      (acceptedPathsList &&
                        values?.["basicVisit_typeofvisit"] ===
                          "Follow Up Visit")
                        ? true
                        : disabled
                    }
                    viewMode={viewMode}
                    splits={splits}
                    edit_patientform={edit_patientform}
                    attr={attribute}
                    touched={touched}
                    errors={errors}
                    attribute={attribute}
                    name={name}
                    setValues={setValues}
                    size="small"
                    label={label}
                    defaultValue={defaultValue}
                    dependent={dependent}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    values={values}
                    removeDependence={removeDependence}
                    checkfieldvalue={checkfieldvalue}
                    commentData={commentData}
                    encountedDataLatestStatus={encountedDataLatestStatus}
                    options={options}
                    required={required}
                    newVisit={newVisit}
                    edit={edit}
                    parentType={parentType}
                    parentName={parentName}
                    followuplable={followuplable}
                    section={attribute.section}
                    handleFocus={handleFocus}
                    removedynamicfields={removedynamicfields}
                  ></MgpToggleButtonGroup>

                  <Box position="absolute" top={0} right={-20}>
                    {commentComponent}
                  </Box>
                </Box>
              </Grid>
            ) : null;
          } else {
            return !hide ? (
              <Grid item md={col} sm={col}>
                <Box position="relative">
                  <MgpToggleButtonGroup
                    encountedDatamain={encountedDatamain}
                    disabled={
                      acceptedPathsList?.includes(
                        urlWindow.pathname.split("/")[3]
                      ) ||
                      (acceptedPathsList &&
                        values?.["basicVisit_typeofvisit"] ===
                          "Follow Up Visit")
                        ? true
                        : disabled
                    }
                    edit_patientform={edit_patientform}
                    viewMode={viewMode}
                    removeDependence={removeDependence}
                    checkfieldvalue={checkfieldvalue}
                    setFieldValue={setFieldValue}
                    splits={splits}
                    attr={attribute}
                    touched={touched}
                    errors={errors}
                    name={name}
                    setValues={setValues}
                    attribute={attribute}
                    size="small"
                    label={label}
                    defaultValue={defaultValue}
                    commentData={commentData}
                    encountedDataLatestStatus={encountedDataLatestStatus}
                    dependent={dependent}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    values={values}
                    options={options}
                    required={required}
                    newVisit={newVisit}
                    edit={edit}
                    parentType={parentType}
                    parentName={parentName}
                    followuplable={followuplable}
                    section={attribute.section}
                    handleFocus={handleFocus}
                    removedynamicfields={removedynamicfields}
                  ></MgpToggleButtonGroup>
                  <Box position="absolute" top={0} right={-20}>
                    {commentComponent}
                  </Box>
                </Box>
              </Grid>
            ) : null;
          }
        }
      }
      break;

    case "select":
      if (dependentreverse) {
        if (
          typeof values[dependent] === "object" ||
          typeof values[dependent] === "undefined" ||
          values[dependent] === "" ||
          values[dependent] === false
        ) {
          error = "";
          touched = "";
          required = true;
          disabled = false;
          hide = false;
        } else {
          error = "";
          touched = "";
          required = false;
          disabled = true;
          hide = false;
        }
      } else if (removeFiledsFromDropDown) {
        if (values?.[removeoptionsdependent]) {
          if (Array.isArray(values[removeoptionsdependent])) {
            optionsAfterRemoveing.forEach((key) => {
              if (
                Array.isArray(values?.[removeoptionsdependent]) &&
                values?.[removeoptionsdependent].includes(key)
              ) {
                options = options.filter((map) => map === key);
              }
            });
          } else {
            Object.keys(optionsAfterRemoveing).map((keys) => {
              if (values?.[dependent] === keys) {
                options = optionsAfterRemoveing[keys];
              }
            });
          }
        }
      } else {
        if (dependent) {
          value = values?.[dependent];
          if (fieldValue === "") {
            if (value) {
              disabled = false;
            }
          } else {
            if (fieldValue === value) {
              required = true;
              disabled = false;
              hide = false;
            } else if (
              value &&
              Array.isArray(fieldValue) &&
              fieldValue.some((elems) => value?.includes(elems))
            ) {
              required = true;
              disabled = false;
              hide = false;
            } else {
              error = "";
              touched = "";
              required = false;
              disabled = true;
              hide = hidden ? true : false;
            }
          }
        }
      }
      if (Array.isArray(options?.[0])) {
        if (fieldValue.some((elems) => value?.includes(elems))) {
          disabled = false;
          required = true;
        }
      }
      if (removevalesfromoptions) {
        if (values?.[optionsdependent] !== optionsfieldValue) {
          options = options.filter((e) => e !== "Pregnancy Planning");
        }
      }

      if (onlyBasic === undefined) {
        if (visitType === undefined) {
          return !hide ? (
            <Grid item md={col}>
              <Box position="relative">
                <DropDownCrf
                  options={options}
                  splits={splits}
                  updatethevalue={updatethevalue}
                  label={label}
                  name={name}
                  size="small"
                  viewMode={viewMode}
                  filter={filter}
                  attribute={attribute}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  helperText={touched[name] && errors[name]}
                  dependenValue={dependenValue}
                  disabled={
                    acceptedPathsList?.includes(
                      urlWindow.pathname.split("/")[3]
                    ) ||
                    (acceptedPathsList &&
                      values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                      ? true
                      : disabled
                  }
                  edit_patientform={edit_patientform}
                  removeDependence={removeDependence}
                  removeoptionsdependent={removeoptionsdependent}
                  optionsAfterRemoveing={optionsAfterRemoveing}
                  checkfieldvalue={checkfieldvalue}
                  setValues={setValues}
                  commentData={commentData}
                  encountedDataLatestStatus={encountedDataLatestStatus}
                  handleBlur={handleBlur}
                  dependent={dependent}
                  required={required}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  fieldValue={fieldValue}
                  values={values}
                  handleChange={handleChange}
                  multiselect={multiselect}
                  edit={edit}
                  parentName={parentName}
                  parentType={parentType}
                  selectedcity={selectedcity}
                  serotype={serotype}
                  setserotype={setserotype}
                  removeFiledsFromDropDown={removeFiledsFromDropDown}
                  setnotemessage={setnotemessage}
                  setdefaultvalue={setdefaultvalue}
                ></DropDownCrf>
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            </Grid>
          ) : null;
        } else if (visitType === newVisit) {
          return !hide ? (
            <Grid item md={col}>
              <Box position="relative">
                <DropDownCrf
                  options={options}
                  splits={splits}
                  label={label}
                  updatethevalue={updatethevalue}
                  name={name}
                  size="small"
                  attribute={attribute}
                  filter={filter}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  helperText={touched[name] && errors[name]}
                  dependenValue={dependenValue}
                  fieldValue={fieldValue}
                  disabled={
                    acceptedPathsList?.includes(
                      urlWindow.pathname.split("/")[3]
                    ) ||
                    (acceptedPathsList &&
                      values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                      ? true
                      : disabled
                  }
                  edit_patientform={edit_patientform}
                  removeFiledsFromDropDown={removeFiledsFromDropDown}
                  removeoptionsdependent={removeoptionsdependent}
                  optionsAfterRemoveing={optionsAfterRemoveing}
                  handleBlur={handleBlur}
                  dependent={dependent}
                  required={required}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  removeDependence={removeDependence}
                  checkfieldvalue={checkfieldvalue}
                  setValues={setValues}
                  handleChange={handleChange}
                  multiselect={multiselect}
                  edit={edit}
                  parentName={parentName}
                  parentType={parentType}
                  selectedcity={selectedcity}
                  serotype={serotype}
                  setserotype={setserotype}
                  setnotemessage={setnotemessage}
                  setdefaultvalue={setdefaultvalue}
                ></DropDownCrf>
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            </Grid>
          ) : null;
        }
      }
      if (onlyBasic && isBaselineVisit) {
        if (visitType === undefined) {
          return !hide ? (
            <Grid item md={col}>
              <Box position="relative">
                <DropDownCrf
                  options={options}
                  splits={splits}
                  updatethevalue={updatethevalue}
                  label={label}
                  name={name}
                  size="small"
                  viewMode={viewMode}
                  filter={filter}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  helperText={touched[name] && errors[name]}
                  dependenValue={dependenValue}
                  attribute={attribute}
                  disabled={
                    acceptedPathsList?.includes(
                      urlWindow.pathname.split("/")[3]
                    ) ||
                    (acceptedPathsList &&
                      values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                      ? true
                      : disabled
                  }
                  removeFiledsFromDropDown={removeFiledsFromDropDown}
                  removeoptionsdependent={removeoptionsdependent}
                  optionsAfterRemoveing={optionsAfterRemoveing}
                  edit_patientform={edit_patientform}
                  removeDependence={removeDependence}
                  checkfieldvalue={checkfieldvalue}
                  setValues={setValues}
                  commentData={commentData}
                  encountedDataLatestStatus={encountedDataLatestStatus}
                  handleBlur={handleBlur}
                  dependent={dependent}
                  required={required}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  fieldValue={fieldValue}
                  values={values}
                  handleChange={handleChange}
                  multiselect={multiselect}
                  edit={edit}
                  parentName={parentName}
                  parentType={parentType}
                  selectedcity={selectedcity}
                  serotype={serotype}
                  setserotype={setserotype}
                  setnotemessage={setnotemessage}
                  setdefaultvalue={setdefaultvalue}
                ></DropDownCrf>
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            </Grid>
          ) : null;
        } else if (visitType === newVisit) {
          return !hide ? (
            <Grid item md={col}>
              <Box position="relative">
                <DropDownCrf
                  options={options}
                  splits={splits}
                  label={label}
                  updatethevalue={updatethevalue}
                  name={name}
                  attribute={attribute}
                  size="small"
                  filter={filter}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  helperText={touched[name] && errors[name]}
                  dependenValue={dependenValue}
                  fieldValue={fieldValue}
                  disabled={
                    acceptedPathsList?.includes(
                      urlWindow.pathname.split("/")[3]
                    ) ||
                    (acceptedPathsList &&
                      values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                      ? true
                      : disabled
                  }
                  removeFiledsFromDropDown={removeFiledsFromDropDown}
                  removeoptionsdependent={removeoptionsdependent}
                  optionsAfterRemoveing={optionsAfterRemoveing}
                  edit_patientform={edit_patientform}
                  handleBlur={handleBlur}
                  dependent={dependent}
                  required={required}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  removeDependence={removeDependence}
                  checkfieldvalue={checkfieldvalue}
                  setValues={setValues}
                  handleChange={handleChange}
                  multiselect={multiselect}
                  edit={edit}
                  parentName={parentName}
                  parentType={parentType}
                  selectedcity={selectedcity}
                  serotype={serotype}
                  setserotype={setserotype}
                  setnotemessage={setnotemessage}
                  setdefaultvalue={setdefaultvalue}
                ></DropDownCrf>
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            </Grid>
          ) : null;
        }
      }

      if (!onlyBasic && isFollowUpVisit) {
        if (visitType === undefined) {
          return !hide ? (
            <Grid item md={col}>
              <Box position="relative">
                <DropDownCrf
                  options={options}
                  splits={splits}
                  updatethevalue={updatethevalue}
                  label={label}
                  attribute={attribute}
                  name={name}
                  size="small"
                  viewMode={viewMode}
                  filter={filter}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  helperText={touched[name] && errors[name]}
                  dependenValue={dependenValue}
                  disabled={
                    acceptedPathsList?.includes(
                      urlWindow.pathname.split("/")[3]
                    ) ||
                    (acceptedPathsList &&
                      values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                      ? true
                      : disabled
                  }
                  removeFiledsFromDropDown={removeFiledsFromDropDown}
                  removeoptionsdependent={removeoptionsdependent}
                  optionsAfterRemoveing={optionsAfterRemoveing}
                  edit_patientform={edit_patientform}
                  removeDependence={removeDependence}
                  checkfieldvalue={checkfieldvalue}
                  setValues={setValues}
                  commentData={commentData}
                  encountedDataLatestStatus={encountedDataLatestStatus}
                  handleBlur={handleBlur}
                  dependent={dependent}
                  required={required}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  fieldValue={fieldValue}
                  values={values}
                  handleChange={handleChange}
                  multiselect={multiselect}
                  edit={edit}
                  parentName={parentName}
                  parentType={parentType}
                  selectedcity={selectedcity}
                  serotype={serotype}
                  setserotype={setserotype}
                  setnotemessage={setnotemessage}
                  setdefaultvalue={setdefaultvalue}
                ></DropDownCrf>
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            </Grid>
          ) : null;
        } else if (visitType === newVisit) {
          return !hide ? (
            <Grid item md={col}>
              <Box position="relative">
                <DropDownCrf
                  options={options}
                  splits={splits}
                  label={label}
                  updatethevalue={updatethevalue}
                  name={name}
                  size="small"
                  attribute={attribute}
                  filter={filter}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  helperText={touched[name] && errors[name]}
                  dependenValue={dependenValue}
                  fieldValue={fieldValue}
                  disabled={
                    acceptedPathsList?.includes(
                      urlWindow.pathname.split("/")[3]
                    ) ||
                    (acceptedPathsList &&
                      values?.["basicVisit_typeofvisit"] === "Follow Up Visit")
                      ? true
                      : disabled
                  }
                  removeFiledsFromDropDown={removeFiledsFromDropDown}
                  removeoptionsdependent={removeoptionsdependent}
                  optionsAfterRemoveing={optionsAfterRemoveing}
                  edit_patientform={edit_patientform}
                  handleBlur={handleBlur}
                  dependent={dependent}
                  required={required}
                  touched={touched}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  values={values}
                  removeDependence={removeDependence}
                  checkfieldvalue={checkfieldvalue}
                  setValues={setValues}
                  handleChange={handleChange}
                  multiselect={multiselect}
                  edit={edit}
                  parentName={parentName}
                  parentType={parentType}
                  selectedcity={selectedcity}
                  serotype={serotype}
                  setserotype={setserotype}
                  setnotemessage={setnotemessage}
                  setdefaultvalue={setdefaultvalue}
                ></DropDownCrf>
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            </Grid>
          ) : null;
        }
      }
      break;
    case "Accordion":
      if (
        (showonlybasline &&
          values?.["basicVisit_typeofvisit"] !== "Follow Up Visit") ||
        showonlybasline === undefined
      ) {
        if (dependentreverse) {
          if (
            typeof values[dependent] === "object" ||
            typeof values[dependent] === "undefined" ||
            values[dependent] === "" ||
            values[dependent] === false
          ) {
            error = "";
            // touched = "";
            required = true;
            disabled = false;
            hide = false;
          } else {
            error = "";
            touched = "";
            required = false;
            disabled = true;
            hide = false;
          }
        } else {
          if (reversedependent) {
            value = values?.[dependent];
            if (fieldValue === "") {
              if (value) {
                disabled = false;
              }
            } else {
              if (
                values?.[reversedependent] === "" ||
                values?.[reversedependent] === null ||
                values?.[reversedependent] === false
              ) {
                disabled = false;
              } else if (values?.[reversedependent] === fieldValue) {
                disabled = true;
              }
            }
          } else if (dependent && fieldValue !== "") {
            value = values[dependent];
            if (fieldValue === value) {
              required = true;
              disabled = false;
              hide = false;
            } else if (
              value &&
              Array.isArray(fieldValue) &&
              fieldValue.some((elems) => value?.includes(elems))
            ) {
              required = true;
              disabled = false;
              hide = false;
            } else {
              error = "";
              touched = "";
              required = false;
              disabled = true;
              hide = true;
            }
          }

          if (dependents) {
            value = Number(values[dependents]);

            if (parseInt(fieldValues) <= parseInt(value)) {
              hide = false;
              disabled = false;
            } else {
              hide = hidden ? true : false;
              disabled = false;
            }
          }
        }

        if (onlyBasic === undefined) {
          return !hide ? (
            <Grid item md={col}>
              <Box position="relative">
                <MgpAccordion
                  name={name}
                  title={label}
                  splits={splits}
                  required={required}
                  options={options}
                  values={values}
                  touched={touched}
                  handleChange={handleChange}
                  errors={errors}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  attribute={attribute}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  disabled={disabled}
                  obj={obj}
                  removeDependence={removeDependence}
                  checkfieldvalue={checkfieldvalue}
                  setValues={setValues}
                  newVisit={newVisit}
                  commentData={commentData}
                  commentComponent={null}
                  setCommentData={setCommentData}
                  encountedDataLatestStatus={encountedDataLatestStatus}
                  viewMode={viewMode}
                  checkboxRequired={checkboxRequired}
                  setFieldTouched={setFieldTouched}
                  edit={edit}
                  enableEnter={enableEnter}
                  parentSection={parentSection}
                  reopen={reopen}
                  setserotype={setserotype}
                  setnotemessage={setnotemessage}
                  mainTitle={mainTitle}
                  followuplable={followuplable}
                  setErrors={setErrors}
                  setTouched={setTouched}
                  submitErrors={submitErrors}
                  submitTouched={submitTouched}
                  setdefaultvalue={setdefaultvalue}
                  name_change={name_change}
                />
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            </Grid>
          ) : null;
        }
        if (onlyBasic && isBaselineVisit) {
          return !hide ? (
            <Grid item md={col}>
              <Box position="relative">
                <MgpAccordion
                  followuplable={followuplable}
                  mainTitle={mainTitle}
                  name={name}
                  title={label}
                  splits={splits}
                  required={required}
                  attribute={attribute}
                  options={options}
                  values={values}
                  touched={touched}
                  handleChange={handleChange}
                  errors={errors}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  disabled={disabled}
                  obj={obj}
                  removeDependence={removeDependence}
                  checkfieldvalue={checkfieldvalue}
                  setValues={setValues}
                  newVisit={newVisit}
                  commentData={commentData}
                  commentComponent={null}
                  setCommentData={setCommentData}
                  encountedDataLatestStatus={encountedDataLatestStatus}
                  viewMode={viewMode}
                  checkboxRequired={checkboxRequired}
                  setFieldTouched={setFieldTouched}
                  edit={edit}
                  enableEnter={enableEnter}
                  parentSection={parentSection}
                  setErrors={setErrors}
                  setTouched={setTouched}
                  submitErrors={submitErrors}
                  submitTouched={submitTouched}
                  setdefaultvalue={setdefaultvalue}
                  name_change={name_change}
                />
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            </Grid>
          ) : null;
        }
        if (!onlyBasic && isFollowUpVisit) {
          return !hide ? (
            <Grid item md={col}>
              <Box position="relative">
                <MgpAccordion
                  followuplable={followuplable}
                  name={name}
                  mainTitle={mainTitle}
                  title={label}
                  splits={splits}
                  attribute={attribute}
                  required={required}
                  options={options}
                  values={values}
                  touched={touched}
                  handleChange={handleChange}
                  errors={errors}
                  error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
                  handleBlur={handleBlur}
                  setFieldValue={setFieldValue}
                  disabled={disabled}
                  obj={obj}
                  removeDependence={removeDependence}
                  checkfieldvalue={checkfieldvalue}
                  setValues={setValues}
                  newVisit={newVisit}
                  commentData={commentData}
                  commentComponent={null}
                  setCommentData={setCommentData}
                  encountedDataLatestStatus={encountedDataLatestStatus}
                  viewMode={viewMode}
                  checkboxRequired={checkboxRequired}
                  setFieldTouched={setFieldTouched}
                  edit={edit}
                  enableEnter={enableEnter}
                  parentSection={parentSection}
                  setErrors={setErrors}
                  setTouched={setTouched}
                  submitErrors={submitErrors}
                  submitTouched={submitTouched}
                  setdefaultvalue={setdefaultvalue}
                  name_change={name_change}
                />
                <Box position="absolute" top={-28} right={-20}>
                  {commentComponent}
                </Box>
              </Box>
            </Grid>
          ) : null;
        }
      }
      break;

    case "MenuList":
      return (
        // <Grid>
        <MgpMenuList
          options={options}
          label={label}
          splits={splits}
          touched={touched}
          errors={errors}
          values={values}
          error={Boolean(submitTouched?.[name] && submitErrors?.[name])}
          obj={obj}
          setValues={setValues}
          handleBlur={handleBlur}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
          setFieldTouched={setFieldTouched}
          viewMode={viewMode}
          newVisit={newVisit}
          encountedDataLatestStatus={encountedDataLatestStatus}
          commentData={commentData}
          setCommentData={setCommentData}
          edit={edit}
          patientActive={patientActive}
          parentSection={parentSection}
          reopen={reopen}
          setserotype={setserotype}
          setnotemessage={setnotemessage}
          setErrors={setErrors}
          setTouched={setTouched}
          submitErrors={submitErrors}
          submitTouched={submitTouched}
          // comment={comment}
        />
        // </Grid>
      );
    case "text":
      if (
        (showonlybasline &&
          values?.["basicVisit_typeofvisit"] !== "Follow Up Visit") ||
        showonlybasline === undefined
      ) {
        if (dependent) {
          value = values?.[dependent];
          if (fieldValue === value) {
            hide = false;
          } else {
            hide = true;
          }
        }
        return (
          <Grid item md={col} sm={col}>
            {!hide ? (
              <div
                style={{
                  fontSize: "14px",
                  paddingTop: paddingT,
                  fontWeight: "500",
                  color: colors.CFRBLUE,
                }}
              >
                {urlWindow.pathname.split("/")[3] === "newVisit" ||
                values?.["basicVisit_typeofvisit"] === "Follow Up Visit" ? (
                  followuplable === undefined ? (
                    hasAsterisk ? (
                      <>
                        {label?.slice(0, -1)}
                        <RedAsterisk>*</RedAsterisk>
                      </>
                    ) : (
                      label
                    )
                  ) : hasAsterisk ? (
                    <>
                      {followuplable?.slice(0, -1)}
                      <RedAsterisk>*</RedAsterisk>
                    </>
                  ) : (
                    followuplable
                  )
                ) : hasAsterisk ? (
                  <>
                    {label?.slice(0, -1)}
                    <RedAsterisk>*</RedAsterisk>
                  </>
                ) : (
                  label
                )}
              </div>
            ) : null}
          </Grid>
        );
      }
      break;
    case "infoList":
      if (section === "ParaClinicalInvestigation") {
        return (
          <Grid item md={col}>
            {
              <Box
                component="section"
                sx={{
                  p: 2,
                  border: "1px solid #BFBFBF",
                  borderRadius: "10px",
                  background: "White",
                }}
              >
                <div class="category">
                  {" "}
                  <strong>Note</strong>{" "}
                  <ul>
                    {" "}
                    <li>
                      If Sero+ is selected for “Type of MG” under Diagnosis
                      section, then one of the results for Serological Tests
                      should be positive
                    </li>{" "}
                    <li>
                      If Sero- is selected for “Type of MG” under Diagnosis
                      section, then underlying results for Serological Tests
                      should be either Negative or Not Done
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </Box>
            }
          </Grid>
        );
      } else {
        return (
          <Grid item md={col}>
            {
              <Box
                component="section"
                sx={{
                  p: 2,
                  border: "1px solid #BFBFBF",
                  borderRadius: "10px",
                  background: "White",
                }}
              >
                <div class="category">
                  {" "}
                  <strong>CSR:</strong>{" "}
                  <ul>
                    {" "}
                    <li>
                      A patient has no symptoms or signs of MG at the time of
                      clinical assessment and has received no therapy for MG
                      since the last visit.
                    </li>{" "}
                    <li>There is no weakness of any muscle.</li>{" "}
                    <li>Isolated weakness of eyelid closure is accepted.</li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div class="category">
                  {" "}
                  <strong>PR:</strong>{" "}
                  <ul>
                    {" "}
                    <li>
                      Same as CSR, but the patient is on immunosuppressive
                      medications with/without pyridostigmine.
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div class="category">
                  {" "}
                  <strong>MM:</strong>{" "}
                  <ul>
                    {" "}
                    <li>
                      The patient has no symptoms of functional limitations from
                      MG but has mild weakness that is only detectable by
                      careful examination, whether or not the patient is on MG
                      therapy.
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div class="category">
                  {" "}
                  <strong>Improved:</strong>{" "}
                  <ul>
                    {" "}
                    <li>
                      Improved ADL by ≥ 2 points, improved MGC by ≥ 3 points,
                      improved MGQ by ≥ 3 points, or improved based on clinical
                      assessment (e.g., MRC sum score, MMT, etc).
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div class="category">
                  {" "}
                  <strong>Unchanged:</strong>{" "}
                  <ul>
                    {" "}
                    <li>
                      No significant changes observed in symptoms or functional
                      limitations.
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div class="category">
                  {" "}
                  <strong>Worse:</strong>{" "}
                  <ul>
                    {" "}
                    <li>
                      Worsened ADL by ≥ 2 points, worsened MGC by ≥ 3 points,
                      worsened MGQ by ≥ 3 points, or worsened based on clinical
                      assessment (e.g., MRC sum score, MMT, etc).
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div class="category">
                  {" "}
                  <strong>Exacerbation:</strong>{" "}
                  <ul>
                    {" "}
                    <li>
                      Worsened and requiring admission, substantial increase in
                      steroids, and/or rescue IVIG or PLEX, crises, or pending
                      crises.
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
                <div class="category">
                  {" "}
                  <strong>Died of MG:</strong>{" "}
                  <ul>
                    {" "}
                    <li>
                      Patient passed away due to complications from Myasthenia
                      Gravis.
                    </li>{" "}
                  </ul>{" "}
                </div>{" "}
              </Box>
            }
          </Grid>
        );
      }
    case "hide":
      return <Grid item md={col}></Grid>;

    case "label":
      return (
        <Grid
          item
          md={col}
          style={{
            paddingLeft: "10px",
            paddingBottom: "1px",
            color: colors.CFRBLUE,
          }}
        >
          <MgpTextLable
            options={options}
            label={label}
            splits={splits}
            touched={touched}
            attr={attribute}
            errors={errors}
            values={values}
            error={error}
            handleBlur={handleBlur}
            handleChange={handleChange}
            setFieldValue={setFieldValue}
          />
        </Grid>
      );
    case "upload":
      const handleUpload = async (files) => {
        // Currently not using directly calling in Uploader Action
        try {
          const formData = new FormData();
          formData.append("file", files);
          const response = await axios.post(
            `${ENV.file_upload_url}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
        } catch (err) {
          console.log("The error happened in file upload is", err);
        }
      };
      //const uploaderRef = useRef(null);
      return (
        <Grid item md={12} sm={6}>
          <Uploader
            defaultFileList={[]}
            accept=".pdf,.jpg,.jpeg,.png,.gif"
            // ref={uploaderRef}
            draggable
            disabled={
              (commentData?.counts?.[name] &&
                (encountedDataLatestStatus === 4 ||
                  encountedDataLatestStatus === 6) &&
                edit) ||
              acceptedPathsList?.includes(urlWindow.pathname.split("/")[3])
                ? encountedDataLatestStatus !== 2 &&
                  values?.["basicVisit_typeofvisit"] === "Baseline Visit"
                  ? false
                  : true
                : disabled || viewMode
                ? true
                : false
            } // You can add other props as needed
            // listType='text'
            multiple={false}
            onChange={handleUpload}
            action={`${ENV.file_upload_url}`}
            onSuccess={(response, file, event, xhr) => {
              setFieldValue("PPCI_consentform", response.object_name);
            }}
            onRemove={() => {
              setFieldValue("PPCI_consentform", "");
            }}
          >
            <div
              style={{
                width: "100%",
                height: 55,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: `2px dotted ${
                  errors[name] && touched[name] ? colors.ERROR : colors.DEVIDER
                }`,
                color: `${colors.PRIMARY}`,
              }}
            >
              <span>Upload Informed Consent Form</span>
            </div>
          </Uploader>
          {values["PPCI_consentform"] && (
            <div style={{ display: "flex" }}>
              <p>
                Uploaded Informed Consent Form:{" "}
                {values["PPCI_consentform"].split("/")[1]}
              </p>

              <Tooltip title="Remove Informed Consent Form">
                <CancelIcon
                  onClick={() => {
                    if (
                      encountedDataLatestStatus != 2 &&
                      values?.["basicVisit_typeofvisit"] === "Baseline Visit"
                    ) {
                      setFieldValue("PPCI_consentform", "");
                    }
                  }}
                  sx={{
                    fontSize: "14px",
                    margin: "4.3px",
                    cursor: "pointer",
                    color:
                      encountedDataLatestStatus !== 2 &&
                      values?.["basicVisit_typeofvisit"] === "Baseline Visit"
                        ? colors.PRIMARY
                        : colors.LIGHT_GRAY,
                  }}
                />
              </Tooltip>
            </div>
          )}
        </Grid>
      );
      break;
    default:
      return <></>;
  }
};

export const getSectionForm = (section, attributes) => {
  return attributes?.filter((attr) => attr.section === section);
};
const urlWindow = window.location;
const acceptedPaths = ["s"];
export const useUtil = () => {
  const { util, setUtil } = React.useContext(UtilContext);
  let user = JSON.parse(StorageHelper.get("user"));
  const [GetForm] = useLazyQuery(GET_FORM);

  useEffect(() => {
    GetForm({
      fetchPolicy: "network-only",
      onCompleted: ({ GetForms }) => {
        // setUtil((previous) => ({ ...previous, forms: GetForms }));

        const modifiedPatientData = {};
        const ppcikeys = [
          "firstname",
          "lastname",
          "email",
          "phonenumber",
          "nationalid",
          "mrnno",
          "consentform",
        ];

        const addHospitalizations = (obj) => {
          let attribute = JSON.parse(
            JSON.stringify(GetForms?.register_patient?.attribute)
          );
          const existingAttributes = attribute;
          const newAttributes = JSON.parse(JSON.stringify(existingAttributes));

          for (
            let index = 1;
            index <=
            parseInt(obj?.["Hospitalizations_hospitalizationsprimarilyduemg"]);
            index++
          ) {
            const hospitalizationTemplate = {
              attribute_type: "DYNAMIC",
              name: `Hospitalizations_${index}_ischecked`,
              label: `Hospitalization ${index} *`,
              error: `${index} is required`,
              type: "Accordion",
              regex: "",
              required: false,
              default: "",
              data_type: "STRING",

              dependents: `Hospitalizations_hospitalizationsprimarilyduemg`,
              fieldValues: `${index}`,
              removeDependence: [
                `Hospitalizations_specifyward${index}`,
                `Hospitalizations_mainreasonformghospitalization${index}`,
                `Hospitalizations_mainreasonformghospitalizationothers${index}`,
                `Hospitalizations_underlyingcause${index}`,
                `Hospitalizations_underlyingcauseothers${index}`,
              ],
              checkfieldvalue: ["", "false"],
              index: "",
              format: "boolean",
              hidden: true,
              prefix: "",
              suffix: "",
              mapping_table: "visit_type",
              options: [
                {
                  attribute_type: "DYNAMIC",
                  name: `Hospitalizations_Whatwasthelengthofeachstayindays${index}`,
                  label: `What was the length of each stay in days`,
                  error: `What was the length of each stay in days required`,
                  type: "input",
                  regex: "",
                  required: false,
                  default: "",
                  numberformat: true,
                  limit: 3,
                  hidden: false,
                  checkVisitType: "basicVisit_typeofvisit",
                  onlyBasic: false,
                  data_type: "STRING",
                  index: "",
                  prefix: "",
                  suffix: "",
                  mapping_table: "",
                  mapping_type: "enum",
                  encrypted: true,
                  col: "6",
                  section: "Hospitalizations",
                },
                {
                  attribute_type: "DYNAMIC",
                  name: `Hospitalizations_specifyward${index}`,
                  label: `Please specify the ward *`,
                  error: `Please specify the ward is required`,
                  type: "toggle",
                  hidden: true,
                  regex: "",
                  dependentValidation: `Hospitalizations_${index}_ischecked`,
                  fieldValueValidation: "true",
                  format: "String",
                  required: true,
                  default: "",
                  data_type: "STRING",
                  index: "",
                  prefix: "",
                  suffix: "",
                  mapping_table: "visit_types",
                  options: ["ICU", "General Ward"],
                  mapping_type: "enum",
                  encrypted: true,
                  col: 6,
                  section: "Hospitalizations",
                },
                {
                  attribute_type: "DYNAMIC",
                  name: `Hospitalizations_mainreasonformghospitalization${index}`,
                  label: `For each MG-related hospitalization, what was the main reason? *`,
                  error: `For each MG-related hospitalization, what was the main reason? is required`,
                  type: "select",
                  regex: "",
                  required: false,
                  default: "",
                  data_type: "STRING",
                  hidden: true,
                  index: "",
                  prefix: "",
                  suffix: "",
                  format: "String",
                  multiselect: false,
                  dependentValidation: `Hospitalizations_${index}_ischecked`,
                  fieldValueValidation: "true",
                  mapping_table: "visit_type",
                  options: [
                    "Myasthenic crisis (respiratory failure)",
                    "Exacerbation of MG symptoms (muscle weakness, difficulty swallowing)",
                    "Thymectomy surgery ",
                    "Other",
                  ],
                  removeDependence: [
                    `Hospitalizations_underlyingcause${index}`,
                  ],
                  checkfieldvalue: ["Thymectomy surgery ", "Other"],

                  mapping_type: "enum",
                  encrypted: true,
                  col: 6,
                  padding: "25px",
                  section: "Hospitalizations",
                },
                {
                  attribute_type: "DYNAMIC",
                  name: `Hospitalizations_mainreasonformghospitalizationothers${index}`,
                  label: `Please Specify *`,
                  error: `MG-related hospitalization is required`,
                  type: "input",
                  regex: "",
                  required: false,
                  default: "",
                  dependent: `Hospitalizations_mainreasonformghospitalization${index}`,
                  hidden: true,
                  dependentValidation: `Hospitalizations_mainreasonformghospitalization${index}`,
                  fieldValue: ["Other"],
                  fieldValueValidation: ["Other"],
                  data_type: "STRING",
                  index: "",
                  prefix: "",
                  suffix: "",
                  mapping_table: "",
                  mapping_type: "enum",
                  encrypted: true,
                  col: 6,
                  section: "Hospitalizations",
                },
                {
                  attribute_type: "DYNAMIC",
                  name: `Hospitalizations_underlyingcause${index}`,
                  label: `What was the Underlying cause? *`,
                  error: `What was the Underlying cause? is required`,
                  type: "select",
                  hidden: true,
                  regex: "",
                  default: "",
                  dependent: `Hospitalizations_mainreasonformghospitalization${index}`,
                  fieldValue: [
                    "Myasthenic crisis (respiratory failure)",
                    "Exacerbation of MG symptoms (muscle weakness, difficulty swallowing)",
                  ],
                  dependentValidation: `Hospitalizations_mainreasonformghospitalization${index}`,
                  fieldValueValidation: [
                    "Myasthenic crisis (respiratory failure)",
                    "Exacerbation of MG symptoms (muscle weakness, difficulty swallowing)",
                  ],
                  required: false,
                  disabled: true,
                  data_type: "STRING",
                  index: "",
                  prefix: "",
                  suffix: "",
                  format: "array",
                  multiselect: true,
                  mapping_table: "visit_type",
                  options: [
                    "Respiratory Infections",
                    "Other Infections",
                    "Cardiac Causes",
                    "Medication Side Effects or Allergic Reactions",
                    "Non-Compliance",
                    "Due to Medication Tapering",
                    "Other",
                  ],
                  mapping_type: "enum",
                  encrypted: true,
                  col: 6,
                  padding: "25px",
                  section: "Hospitalizations",
                },
                {
                  attribute_type: "DYNAMIC",
                  name: `Hospitalizations_underlyingcauseothers${index}`,
                  label: `Please Specify *`,
                  error: `MG-related hospitalization is required`,
                  type: "input",
                  regex: "",
                  required: false,
                  default: "",
                  dependent: `Hospitalizations_underlyingcause${index}`,
                  hidden: true,
                  fieldValue: ["Other"],
                  fieldValueValidation: ["Other"],
                  data_type: "STRING",
                  dependentValidation: `Hospitalizations_underlyingcause${index}`,
                  index: "",
                  prefix: "",
                  suffix: "",
                  mapping_table: "",
                  mapping_type: "enum",
                  encrypted: true,
                  col: 6,
                  section: "Hospitalizations",
                },
              ],
              mapping_type: "enum",
              encrypted: true,
              col: "10",
              section: "Hospitalizations",
            };
            newAttributes.push(hospitalizationTemplate);
          }

          for (
            let index = 1;
            index <=
            parseInt(obj?.["PregnancyHistory_pregnancyhistoryafterdiagnosis"]);
            index++
          ) {
            const pregnancyHistoryTemplate = {
              attribute_type: "DYNAMIC",
              name: `PregnancyHistory_pregnancy${index}_ischecked`,
              label: `Pregnancy ${index}`,
              error: `Pregnancy ${index} is required`,
              type: "Accordion",
              regex: "",
              required: false,
              default: "",
              data_type: "STRING",

              dependents: `PregnancyHistory_pregnancyhistoryafterdiagnosis`,
              fieldValues: `${index}`,
              removeDependence: [
                `PregnancyHistory_pregnancy_previouspregnancyoutcomes${index}`,
                `PregnancyHistory_pregnancy_immunosuppressivemedication${index}`,
              ],
              checkfieldvalue: ["", "false"],
              index: "",
              format: "boolean",
              hidden: true,
              prefix: "",
              suffix: "",
              mapping_table: "visit_type",
              options: [
                {
                  attribute_type: "DYNAMIC",
                  name: `PregnancyHistory_pregnancy_previouspregnancyoutcomes${index}`,
                  label: `Previous Pregnancy Outcomes`,
                  error: `Previous Pregnancy Outcomes ${index} is required`,
                  type: "select",
                  regex: "",
                  required: true,
                  default: "",
                  data_type: "STRING",
                  index: "",
                  prefix: "",
                  suffix: "",
                  multiselect: false,
                  dependentValidation: `PregnancyHistory_pregnancy${index}_ischecked`,
                  fieldValueValidation: "true",
                  format: "String",
                  mapping_table: "visit_type",
                  options: [
                    "Full-term delivery",
                    "Preterm delivery",
                    "Miscarriage",
                    "Stillbirth",
                    "Elective abortion",
                    "Other",
                  ],
                  mapping_type: "enum",
                  encrypted: true,
                  col: 6,
                  padding: "0px",
                  size: "small",
                  section: "PregnancyHistory",
                },
                {
                  attribute_type: "DYNAMIC",
                  name: `PregnancyHistory_pregnancy_immunosuppressivemedication${index}`,
                  label:
                    "Use of immunosuppressive medication during previous pregnancy",
                  error: `Use of immunosuppressive medication during previous pregnancy ${index} is required`,
                  type: "toggle",
                  regex: "",
                  required: true,
                  default: "",
                  data_type: "STRING",
                  index: "",
                  prefix: "",
                  suffix: "",
                  format: "String",
                  dependentValidation: `PregnancyHistory_pregnancy${index}_ischecked`,
                  fieldValueValidation: "true",
                  mapping_table: "visit_types",
                  options: ["Yes", "No"],
                  mapping_type: "enum",
                  encrypted: true,
                  col: 6,
                  size: "small",
                  section: "PregnancyHistory",
                },
                {
                  attribute_type: "DYNAMIC",
                  name: `PregnancyHistory_pregnancy_previouspregnancyoutcomesothers${index}`,
                  label: "Please Specify",
                  error: `Previous Pregnancy Outcomes ${index} is required`,
                  type: "input",
                  regex: "",
                  required: false,
                  default: "",
                  dependent: `PregnancyHistory_pregnancy_previouspregnancyoutcomes${index}`,
                  hidden: true,
                  fieldValue: ["Other"],
                  fieldValueValidation: ["Other"],
                  data_type: "STRING",
                  index: "",
                  prefix: "",
                  suffix: "",
                  mapping_table: "",
                  mapping_type: "enum",
                  encrypted: true,
                  col: "12",
                  section: "PregnancyHistory",
                },
              ],
              mapping_type: "enum",
              encrypted: true,
              col: "12",
              section: "PregnancyHistory",
            };

            newAttributes.push(pregnancyHistoryTemplate);
          }

          return newAttributes;
        };

        const filterForOthers = (obj, obj2, gender) => {
          let attribute = JSON.parse(
            JSON.stringify(GetForms?.register_patient?.attribute)
          );
          let sections = JSON.parse(
            JSON.stringify(GetForms?.register_patient?.sections)
          );
          // Find and remove the "LivingStatus" section
          if (obj2?.basicVisit_typeofvisit === "Baseline Visit") {
          }
          if (obj2) {
            attribute = addHospitalizations(obj2);
            // Find and remove the "LivingStatus" section
            if (obj2?.basicVisit_typeofvisit === "Baseline Visit") {
              sections = sections.filter(
                (section) => section.key !== "LivingStatus"
              );
            }
            if (
              obj2?.TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_ImmunosuppressiveTreatmentCurrentMedication ===
              undefined
            ) {
              setUtil((previous) => ({
                ...previous,
                forms: {
                  register_patient: {
                    ...GetForms?.register_patient,
                    attribute,
                    sections,
                  },
                },
              }));
              return;
            }

            if (
              obj2?.TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_ImmunosuppressiveTreatmentCurrentMedication !==
                "" &&
              obj2?.basicVisit_typeofvisit !== "Baseline Visit"
            ) {
              let filterOptions = Object.keys(
                JSON.parse(
                  obj2?.TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_ImmunosuppressiveTreatmentCurrentMedication
                )?.value
              );

              for (let i = 0; i < attribute.length; i++) {
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_isChecked"
                ) {
                  for (
                    let j = 0;
                    j < attribute[i].options[0]?.options?.length;
                    j++
                  ) {
                    let children = [];
                    for (
                      let k = 0;
                      k < attribute[i].options[0]?.options[j].children.length;
                      k++
                    ) {
                      let elem =
                        attribute[i].options[0]?.options[j].children[k];
                      if (elem.label === "Use on going?") {
                        if (
                          (elem.value === "Azathioprine -> Use on going?" &&
                            filterOptions.includes(
                              "Azathioprine -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "Mycophenolate Mofetil -> Use on going?" &&
                            filterOptions.includes(
                              "Mycophenolate Mofetil -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Tacrolimus -> Use on going?" &&
                            filterOptions.includes(
                              "Tacrolimus -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Cyclosporine -> Use on going?" &&
                            filterOptions.includes(
                              "Cyclosporine -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Methotrexate -> Use on going?" &&
                            filterOptions.includes(
                              "Methotrexate -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Cyclophosphamide -> Use on going?" &&
                            filterOptions.includes(
                              "Cyclophosphamide -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Rituximab -> Use on going?" &&
                            filterOptions.includes(
                              "Rituximab -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "C5 inhibitor, Eculizumab -> Use on going?" &&
                            filterOptions.includes(
                              "C5 inhibitor, Eculizumab -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "C5 inhibitor, Ravulizumab -> Use on going?" &&
                            filterOptions.includes(
                              "C5 inhibitor, Ravulizumab -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "C5 inhibitor Zilucoplan -> Use on going?" &&
                            filterOptions.includes(
                              "C5 inhibitor Zilucoplan -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "FcRn inhibitors, Efgartigimod alfa -> Use on going?" &&
                            filterOptions.includes(
                              "FcRn inhibitors, Efgartigimod alfa -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "FcRN inhibitors, Rozanolixizumab -> Use on going?" &&
                            filterOptions.includes(
                              "FcRN inhibitors, Rozanolixizumab -> Dosage -> Other_textbox"
                            ))
                        ) {
                          children.push(elem);
                        }
                      } else {
                        children.push(elem);
                      }
                    }
                    attribute[i].options[0].options[j].children = children;
                  }
                }
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_corticosteroidstreatmentCurrentMedication_isChecked"
                ) {
                  if (
                    obj2.TreatmentIntervention_corticosteroidstreatmentCurrentMedication_isChecked ===
                    true
                  ) {
                    attribute[i].options[1].checkpreviousencounter = false;
                    attribute[i].options[1].required = true;
                  }
                }
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_acetylcholinesteraseinhibitorsCurrentMedication_isChecked"
                ) {
                  if (
                    obj2.TreatmentIntervention_acetylcholinesteraseinhibitorsCurrentMedication_isChecked ===
                    true
                  ) {
                    attribute[i].options[1].checkpreviousencounter = false;
                    attribute[i].options[1].required = true;
                  }
                }
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_CyclicintravenousimmunoglobulinsIVIGCurrentMedication_isChecked"
                ) {
                  if (
                    obj2.TreatmentIntervention_CyclicintravenousimmunoglobulinsIVIGCurrentMedication_isChecked ===
                    true
                  ) {
                    attribute[i].options[1].checkpreviousencounter = false;
                    attribute[i].options[1].required = true;
                  }
                }
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_CyclicPlasmapheresisPLEXCurrentMedication_isChecked"
                ) {
                  if (
                    obj2.TreatmentIntervention_CyclicPlasmapheresisPLEXCurrentMedication_isChecked ===
                    true
                  ) {
                    attribute[i].options[1].checkpreviousencounter = false;
                    attribute[i].options[1].required = true;
                  }
                }
              }
            } else {
              for (let i = 0; i < attribute.length; i++) {
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_isChecked"
                ) {
                  for (
                    let j = 0;
                    j < attribute[i].options[0]?.options?.length;
                    j++
                  ) {
                    let children = [];
                    for (
                      let k = 0;
                      k < attribute[i].options[0]?.options[j].children.length;
                      k++
                    ) {
                      let elem =
                        attribute[i].options[0]?.options[j].children[k];
                      if (elem.label === "Use on going?") {
                        if (
                          elem.value === "Azathioprine -> Use on going?" ||
                          elem.value ===
                            "Mycophenolate Mofetil -> Use on going?" ||
                          elem.value === "Tacrolimus -> Use on going?" ||
                          elem.value === "Cyclosporine -> Use on going?" ||
                          elem.value === "Methotrexate -> Use on going?" ||
                          elem.value === "Cyclophosphamide -> Use on going?" ||
                          elem.value === "Rituximab -> Use on going?" ||
                          elem.value ===
                            "C5 inhibitor, Eculizumab -> Use on going?" ||
                          elem.value ===
                            "C5 inhibitor, Ravulizumab -> Use on going?" ||
                          elem.value ===
                            "C5 inhibitor Zilucoplan -> Use on going?" ||
                          elem.value ===
                            "FcRn inhibitors, Efgartigimod alfa -> Use on going?" ||
                          elem.value ===
                            "FcRN inhibitors, Rozanolixizumab -> Use on going?"
                        ) {
                          // children.push(elem)
                        }
                      } else {
                        children.push(elem);
                      }
                    }
                    if (attribute[i]?.options?.[0]?.options?.[j]) {
                      attribute[i].options[0].options[j].children = children;
                    }
                  }
                }

                if (attribute[i].name === "LivingStatus_Isthepatientalive") {
                  attribute[i].required = false;
                }
              }
            }
          } else {
            attribute = addHospitalizations(obj);
            if (obj?.basicVisit_typeofvisit === "Baseline Visit") {
              sections = sections.filter(
                (section) => section.key !== "LivingStatus"
              );
            }
            if (
              obj?.TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_ImmunosuppressiveTreatmentCurrentMedication ===
              undefined
            ) {
              setUtil((previous) => ({
                ...previous,
                forms: {
                  register_patient: {
                    ...GetForms?.register_patient,
                    attribute,
                    sections,
                  },
                },
              }));
              return;
            }

            if (
              obj?.TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_ImmunosuppressiveTreatmentCurrentMedication !==
                "" &&
              obj?.basicVisit_typeofvisit !== "Baseline Visit"
            ) {
              let filterOptions = Object.keys(
                JSON.parse(
                  obj?.TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_ImmunosuppressiveTreatmentCurrentMedication
                )?.value
              );

              for (let i = 0; i < attribute.length; i++) {
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_isChecked"
                ) {
                  for (
                    let j = 0;
                    j < attribute[i].options[0]?.options?.length;
                    j++
                  ) {
                    let children = [];
                    for (
                      let k = 0;
                      k < attribute[i].options[0]?.options[j].children.length;
                      k++
                    ) {
                      let elem =
                        attribute[i].options[0]?.options[j].children[k];
                      if (elem.label === "Use on going?") {
                        if (
                          (elem.value === "Azathioprine -> Use on going?" &&
                            filterOptions.includes(
                              "Azathioprine -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "Mycophenolate Mofetil -> Use on going?" &&
                            filterOptions.includes(
                              "Mycophenolate Mofetil -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Tacrolimus -> Use on going?" &&
                            filterOptions.includes(
                              "Tacrolimus -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Cyclosporine -> Use on going?" &&
                            filterOptions.includes(
                              "Cyclosporine -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Methotrexate -> Use on going?" &&
                            filterOptions.includes(
                              "Methotrexate -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Cyclophosphamide -> Use on going?" &&
                            filterOptions.includes(
                              "Cyclophosphamide -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value === "Rituximab -> Use on going?" &&
                            filterOptions.includes(
                              "Rituximab -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "C5 inhibitor, Eculizumab -> Use on going?" &&
                            filterOptions.includes(
                              "C5 inhibitor, Eculizumab -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "C5 inhibitor, Ravulizumab -> Use on going?" &&
                            filterOptions.includes(
                              "C5 inhibitor, Ravulizumab -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "C5 inhibitor Zilucoplan -> Use on going?" &&
                            filterOptions.includes(
                              "C5 inhibitor Zilucoplan -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "FcRn inhibitors, Efgartigimod alfa -> Use on going?" &&
                            filterOptions.includes(
                              "FcRn inhibitors, Efgartigimod alfa -> Dosage -> Other_textbox"
                            )) ||
                          (elem.value ===
                            "FcRN inhibitors, Rozanolixizumab -> Use on going?" &&
                            filterOptions.includes(
                              "FcRN inhibitors, Rozanolixizumab -> Dosage -> Other_textbox"
                            ))
                        ) {
                          children.push(elem);
                        }
                      } else {
                        children.push(elem);
                      }
                    }
                    if (attribute[i]?.options?.[0]?.options?.[j]) {
                      attribute[i].options[0].options[j].children = children;
                    }
                  }
                }
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_corticosteroidstreatmentCurrentMedication_isChecked"
                ) {
                  if (
                    obj.TreatmentIntervention_corticosteroidstreatmentCurrentMedication_isChecked ===
                    true
                  ) {
                    attribute[i].options[1].checkpreviousencounter = false;
                    attribute[i].options[1].required = true;
                  }
                }
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_acetylcholinesteraseinhibitorsCurrentMedication_isChecked"
                ) {
                  if (
                    obj.TreatmentIntervention_acetylcholinesteraseinhibitorsCurrentMedication_isChecked ===
                    true
                  ) {
                    attribute[i].options[1].checkpreviousencounter = false;
                    attribute[i].options[1].required = true;
                  }
                }
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_CyclicintravenousimmunoglobulinsIVIGCurrentMedication_isChecked"
                ) {
                  if (
                    obj.TreatmentIntervention_CyclicintravenousimmunoglobulinsIVIGCurrentMedication_isChecked ===
                    true
                  ) {
                    attribute[i].options[1].checkpreviousencounter = false;
                    attribute[i].options[1].required = true;
                  }
                }
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_CyclicPlasmapheresisPLEXCurrentMedication_isChecked"
                ) {
                  if (
                    obj.TreatmentIntervention_CyclicPlasmapheresisPLEXCurrentMedication_isChecked ===
                    true
                  ) {
                    attribute[i].options[1].checkpreviousencounter = false;
                    attribute[i].options[1].required = true;
                  }
                }
              }
            } else {
              for (let i = 0; i < attribute.length; i++) {
                if (
                  attribute[i].name ===
                  "TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_isChecked"
                ) {
                  for (
                    let j = 0;
                    j < attribute[i].options[0]?.options?.length;
                    j++
                  ) {
                    let children = [];
                    for (
                      let k = 0;
                      k < attribute[i].options[0]?.options[j].children.length;
                      k++
                    ) {
                      let elem =
                        attribute[i].options[0]?.options[j].children[k];
                      if (elem.label === "Use on going?") {
                        if (
                          elem.value === "Azathioprine -> Use on going?" ||
                          elem.value ===
                            "Mycophenolate Mofetil -> Use on going?" ||
                          elem.value === "Tacrolimus -> Use on going?" ||
                          elem.value === "Cyclosporine -> Use on going?" ||
                          elem.value === "Methotrexate -> Use on going?" ||
                          elem.value === "Cyclophosphamide -> Use on going?" ||
                          elem.value === "Rituximab -> Use on going?" ||
                          elem.value ===
                            "C5 inhibitor, Eculizumab -> Use on going?" ||
                          elem.value ===
                            "C5 inhibitor, Ravulizumab -> Use on going?" ||
                          elem.value ===
                            "C5 inhibitor Zilucoplan -> Use on going?" ||
                          elem.value ===
                            "FcRn inhibitors, Efgartigimod alfa -> Use on going?" ||
                          elem.value ===
                            "FcRN inhibitors, Rozanolixizumab -> Use on going?"
                        ) {
                          // children.push(elem)
                        }
                      } else {
                        children.push(elem);
                      }
                    }
                    if (attribute[i]?.options?.[0]?.options?.[j]) {
                      attribute[i].options[0].options[j].children = children;
                    }
                  }
                }

                if (attribute[i].name === "LivingStatus_Isthepatientalive") {
                  attribute[i].required = false;
                }
              }
            }
          }
          if (gender) {
            sections = sections?.filter((e) => e.key !== "PregnancyHistory");
          }
          setUtil((previous) => ({
            ...previous,
            forms: {
              register_patient: {
                ...GetForms?.register_patient,
                attribute,
                sections,
              },
            },
          }));
        };
        const filterForBaseline = (obj, obj2, gender) => {
          let attribute = JSON.parse(
            JSON.stringify(GetForms?.register_patient?.attribute)
          );
          if (obj) {
            attribute = addHospitalizations(obj);
          }

          let sections = JSON.parse(
            JSON.stringify(GetForms?.register_patient?.sections)
          );

          // Find and remove the "LivingStatus" section
          sections = sections.filter(
            (section) => section.key !== "LivingStatus"
          );

          for (let i = 0; i < attribute.length; i++) {
            if (
              attribute[i].name ===
              "TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_isChecked"
            ) {
              for (
                let j = 0;
                j < attribute[i].options[0]?.options?.length;
                j++
              ) {
                let children = [];
                for (
                  let k = 0;
                  k < attribute[i].options[0]?.options[j].children.length;
                  k++
                ) {
                  let elem = attribute[i].options[0]?.options[j].children[k];
                  if (elem.label === "Use on going?") {
                    if (
                      elem.value === "Azathioprine -> Use on going?" ||
                      elem.value === "Mycophenolate Mofetil -> Use on going?" ||
                      elem.value === "Tacrolimus -> Use on going?" ||
                      elem.value === "Cyclosporine -> Use on going?" ||
                      elem.value === "Methotrexate -> Use on going?" ||
                      elem.value === "Cyclophosphamide -> Use on going?" ||
                      elem.value === "Rituximab -> Use on going?" ||
                      elem.value ===
                        "C5 inhibitor, Eculizumab -> Use on going?" ||
                      elem.value ===
                        "C5 inhibitor, Ravulizumab -> Use on going?" ||
                      elem.value ===
                        "C5 inhibitor Zilucoplan -> Use on going?" ||
                      elem.value ===
                        "FcRn inhibitors, Efgartigimod alfa -> Use on going?" ||
                      elem.value ===
                        "FcRN inhibitors, Rozanolixizumab -> Use on going?"
                    ) {
                      // children.push(elem)
                    }
                  } else {
                    children.push(elem);
                  }
                }
                if (attribute[i]?.options?.[0]?.options?.[j]) {
                  attribute[i].options[0].options[j].children = children;
                }
              }
            }

            if (attribute[i].name === "LivingStatus_Isthepatientalive") {
              attribute[i].required = false;
            }
          }

          if (gender) {
            sections = sections?.filter((e) => e.key !== "PregnancyHistory");
          }

          setUtil((previous) => ({
            ...previous,
            forms: {
              register_patient: {
                ...GetForms?.register_patient,
                sections,
                attribute,
              },
            },
          }));
        };
        if (params?.encounterId) {
          setPageLoad(true);
          setUtil((previous) => ({ ...previous, forms: GetForms }));
          GetEncounter({
            variables: { encounterId: parseInt(params?.encounterId) },
            fetchPolicy: "cache-and-network",
            onCompleted: (res) => {
              ViewPatient({
                variables: {
                  patientId: parseInt(res?.GetEncounter?.patient_id),
                },
                fetchPolicy: "cache-and-network",
                onCompleted: (dataresponse) => {
                  setpatientData(dataresponse);
                  GetEncountersByPatientId({
                    variables: {
                      patientId: parseInt(
                        dataresponse?.ViewPatient?.patient_id
                      ),
                    },
                    fetchPolicy: "cache-and-network",
                    onCompleted: (dataresponseencounder) => {
                      if (dataresponse?.ViewPatient?.is_active === false) {
                        setPatientActive(false);
                      }
                      for (const key in dataresponse.ViewPatient) {
                        if (
                          Object.hasOwnProperty.call(
                            dataresponse.ViewPatient,
                            key
                          )
                        ) {
                          if (ppcikeys.includes(key)) {
                            if (key === "firstname") {
                              modifiedPatientData[`PPCI_mgpf`] =
                                dataresponse?.ViewPatient[key];
                            }
                            if (key === "lastname") {
                              modifiedPatientData[`PPCI_mgpl`] =
                                dataresponse?.ViewPatient[key];
                            }
                            if (key === "email") {
                              if (
                                dataresponse.ViewPatient[key] === "undefined" ||
                                dataresponse.ViewPatient[key] === undefined
                              ) {
                                modifiedPatientData[`PPCI_mgpe`] = "";
                              } else {
                                modifiedPatientData[`PPCI_mgpe`] =
                                  dataresponse.ViewPatient[key];
                              }
                            }
                            if (
                              dataresponse.ViewPatient[key] === "undefined" ||
                              dataresponse.ViewPatient[key] === undefined
                            ) {
                              modifiedPatientData[`PPCI_${key}`] = "";
                            } else {
                              modifiedPatientData[`PPCI_${key}`] =
                                dataresponse.ViewPatient[key];
                            }
                          } else {
                            if (key === "age") {
                              if (dataresponse?.ViewPatient?.[key] !== null) {
                                const [num1, num2] =
                                  dataresponse?.ViewPatient?.[key]?.split("/");
                                modifiedPatientData[`demography_year`] = num1;
                                modifiedPatientData[`demography_month`] = num2;
                              } else {
                                modifiedPatientData[`demography_year`] = "";
                                modifiedPatientData[`demography_month`] = "";
                              }
                            } else {
                              modifiedPatientData[`demography_${key}`] =
                                dataresponse.ViewPatient[key];
                            }
                          }
                        }
                      }

                      let obj = {
                        ...preProcessData(res?.GetEncounter?.details),
                      };
                      if (
                        dataresponseencounder?.GetEncountersByPatientId.length >
                        1
                      ) {
                        const filteredEncounterIds =
                          dataresponseencounder.GetEncountersByPatientId.filter(
                            (encounter) =>
                              parseInt(encounter.encounter_id) <
                              parseInt(params?.encounterId)
                          ).map((encounter) =>
                            parseInt(encounter.encounter_id)
                          );

                        GetLatestEncounterByPatientIdEncounterId({
                          variables: {
                            patientId: parseInt(res?.GetEncounter?.patient_id),
                            encounterId: parseInt(filteredEncounterIds?.[0]),
                          },
                          fetchPolicy: "cache-and-network",
                        }).then((res) => {
                          let obj1 = {
                            ...preProcessData(
                              res.data?.GetLatestEncounterByPatientIdEncounterId
                                ?.details
                            ),
                          };
                          if (obj?.basicVisit_typeofvisit !== "") {
                            filterForOthers(
                              obj1,
                              obj,
                              dataresponse?.ViewPatient?.gender === "Male"
                            );
                          } else {
                            filterForBaseline(
                              obj1,
                              undefined,
                              dataresponse?.ViewPatient?.gender === "Male"
                            );
                          }
                        });
                      } else {
                        filterForBaseline(
                          obj,
                          undefined,
                          dataresponse?.ViewPatient?.gender === "Male"
                        );
                      }

                      if (res?.GetEncounter?.details) {
                        const mergedObject = { ...obj, ...modifiedPatientData };
                        setEncountedDatamain(res?.GetEncounter);
                        setEncountedData(mergedObject);
                        setEncountedDataLatestStatus(
                          parseInt(res?.GetEncounter?.status)
                        );
                        GetAllCommentsCount({
                          variables: {
                            encounterId: Number(params?.encounterId),
                          },
                          fetchPolicy: "cache-and-network",
                          onCompleted: (res) => {
                            setPageLoad(false);
                            setCommentData(res?.GetAllCommentsCount);
                          },
                        });
                      }
                    },
                  });
                },
              });
            },
          });
        } else if (params?.patientId) {
          let gendermale = null;
          ViewPatient({
            variables: { patientId: parseInt(params?.patientId) },
            fetchPolicy: "cache-and-network",
          }).then((dataresponse) => {
            setpatientData(dataresponse?.data);
            GetEncountersByPatientId({
              variables: {
                patientId: parseInt(
                  dataresponse?.data?.ViewPatient?.patient_id
                ),
              },
              fetchPolicy: "cache-and-network",
            });
            gendermale = dataresponse?.data?.ViewPatient?.gender === "Male";

            for (const key in dataresponse.data.ViewPatient) {
              if (
                Object.hasOwnProperty.call(dataresponse.data.ViewPatient, key)
              ) {
                if (ppcikeys.includes(key)) {
                  if (key === "firstname") {
                    modifiedPatientData[`PPCI_mgpf`] =
                      dataresponse.data.ViewPatient?.[key];
                  }
                  if (key === "lastname") {
                    modifiedPatientData[`PPCI_mgpl`] =
                      dataresponse.data.ViewPatient?.[key];
                  }
                  if (key === "email") {
                    if (
                      dataresponse.data.ViewPatient?.[key] === "undefined" ||
                      dataresponse.data.ViewPatient?.[key] === undefined
                    ) {
                      modifiedPatientData[`PPCI_mgpe`] = "";
                    } else {
                      modifiedPatientData[`PPCI_mgpe`] =
                        dataresponse.data.ViewPatient?.[key];
                    }
                  }
                  if (
                    dataresponse.data.ViewPatient?.[key] === "undefined" ||
                    dataresponse.data.ViewPatient?.[key] === undefined
                  ) {
                    modifiedPatientData[`PPCI_${key}`] = "";
                  } else {
                    modifiedPatientData[`PPCI_${key}`] =
                      dataresponse.data.ViewPatient?.[key];
                  }
                } else {
                  if (key === "age") {
                    if (
                      dataresponse?.data?.ViewPatient?.[key] !== "" ||
                      dataresponse?.data?.ViewPatient?.[key] !== null
                    ) {
                      const [num1, num2] =
                        dataresponse?.data?.ViewPatient?.[key]?.split("/");
                      modifiedPatientData[`demography_year`] = num1;
                      modifiedPatientData[`demography_month`] = num2;
                    } else {
                      modifiedPatientData[`demography_year`] = "";
                      modifiedPatientData[`demography_month`] = "";
                    }
                  } else {
                    modifiedPatientData[`demography_${key}`] =
                      dataresponse.data.ViewPatient[key];
                  }
                }
              }
            }
          });
          GetLatestEncounterByPatientId({
            variables: { patientId: parseInt(params?.patientId) },
            fetchPolicy: "cache-and-network",
          }).then((res) => {
            setEncountedDatamain(res?.data?.GetLatestEncounterByPatientId);
            let obj = {
              ...preProcessData(
                res.data?.GetLatestEncounterByPatientId?.details
              ),
            };

            filterForOthers(obj, undefined, gendermale);
            if (urlWindow.pathname.split("/")[3] === "newVisit") {
              Object.keys(obj).forEach((key) => {
                if (
                  key.match(/^Hospitalizations_\d+_ischecked$/) ||
                  key.match(
                    /^Hospitalizations_Whatwasthelengthofeachstayindays\d+$/
                  ) ||
                  key.match(/^Hospitalizations_specifyward\d+$/) ||
                  key.match(
                    /^Hospitalizations_mainreasonformghospitalization\d+$/
                  ) ||
                  key.match(
                    /^Hospitalizations_mainreasonformghospitalizationothers\d+$/
                  ) ||
                  key.match(/^Hospitalizations_underlyingcause\d+$/) ||
                  key.match(/^Hospitalizations_underlyingcauseothers\d+$/)
                ) {
                  delete obj[key];
                }
              });
              Object.keys(obj).forEach((key) => {
                if (
                  key.match(/^PregnancyHistory_pregnancy\d+_ischecked$/) ||
                  key.match(
                    /^PregnancyHistory_pregnancy_previouspregnancyoutcomes\d+$/
                  ) ||
                  key.match(
                    /^PregnancyHistory_pregnancy_immunosuppressivemedication\d+$/
                  ) ||
                  key.match(
                    /^PregnancyHistory_pregnancy_previouspregnancyoutcomesothers\d+$/
                  )
                ) {
                  // Extract index from key

                  delete obj[key];
                }
              });
              obj["lastfollowupdate"] = obj["basicVisit_dateofvisit"];
              obj["basicVisit_typeofvisit"] = "Follow Up Visit";
              obj["basicVisit_dateofvisit"] = "";
              obj["basicVisit_formcompletedby"] = "";
              obj["Diagnosis_mgseverityclassificationon_unknown_isChecked"] =
                "";
              obj["Diagnosis_mgseverityclassificationon_MGFA_isChecked"] = "";
              obj[
                "Diagnosis_mgseverityclassificationon_myastheniagravisfoundationofamerica"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_isChecked"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_isChecked"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_isChecked"
              ] = "";
              obj["ClinicalAssessment_singlebreathcounttest_isChecked"] = "";
              obj["ClinicalAssessment_handgriptest_isChecked"] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_selectoption"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_chewing"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_swallowing"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_breathing"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_impairmentabilitytobrush"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_impairmentabilitytoarisechair"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_doublevision"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_eyeliddroop"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisfoundationofamericapostinterventionstatus_totalscore"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_ptosisupwardease"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_doublevisionlateralgaze"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_eyeclosure"
              ] = "";
              obj["ClinicalAssessment_myastheniagraviscompositescore_talking"] =
                "";
              obj["ClinicalAssessment_myastheniagraviscompositescore_chewing"] =
                "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_swallowing"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_breathing"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_neckflexionorextension"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_shoulderabduction"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_hipflexion"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagraviscompositescore_totalscore"
              ] = "";

              // Clinical Assessment - Myasthenia Gravis Quality of Life Item Scale
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_frustratedmg"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubleeyes"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubleeating"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_socialactivity"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_enjoyhobbies"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_needsoffamily"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_makeplans"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_occupationalskills"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_difficultyspeaking"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubledriving"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_depressed"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troublewalking"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troublepublicplaces"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_feeloverwhelmed"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubleperformingpersonal"
              ] = "";
              obj[
                "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_totalscore"
              ] = "";

              obj[
                "ClinicalAssessment_singlebreathcounttest_singlebreathcounttestresult"
              ] = "";

              obj["ClinicalAssessment_handgriptest_righthandibs"] = "";
              obj["ClinicalAssessment_handgriptest_lefthandibs"] = "";
              // Pregnancy History
              obj["PregnancyHistory_currentlypregnant"] = "";
              obj["PregnancyHistory_pregnancyafterdiagnosis"] = "";
              obj["PregnancyHistory_pregnancyhistoryafterdiagnosis"] = "";

              // Hospitalizations
              obj["Hospitalizations_pasterhistory"] = "";
              obj["Hospitalizations_pasthospitalizedhistory"] = "";
              obj["Hospitalizations_hospitalizationsrelatedmyastheniagravis"] =
                "";
              obj["Hospitalizations_hospitalizationsprimarilyduemg"] = "";

              obj["ClinicalExamination_pulse"] = "";
              obj["ClinicalExamination_bloodpressuresystolic"] = "";

              obj["ClinicalExamination_bloodpressurediastolic"] = "";
              obj["ClinicalExamination_BMI"] = "";
              obj["ClinicalExamination_height"] = "";
              obj["ClinicalExamination_weight"] = "";
            }
            const mergedObject = { ...obj, ...modifiedPatientData };

            setEncountedData(mergedObject);
          });
        } else {
          filterForBaseline();
        }
      },
    });
  }, [GetForm]);

  const [pageLoad, setPageLoad] = useState(false);
  const [encountedData, setEncountedData] = useState();
  const [encountedDatamain, setEncountedDatamain] = useState(null);
  const [patientData, setpatientData] = useState(null);

  const [patientActive, setPatientActive] = useState(true);

  const setError = (errors) => {
    setUtil((prev) => {
      return { ...prev, crfErrors: errors };
    });
  };

  const setTouch = (touch) => {
    setUtil((prev) => {
      return { ...prev, crfTouch: touch };
    });
  };
  const [encountedDataLatestStatus, setEncountedDataLatestStatus] =
    useState(null);
  const [commentData, setCommentData] = useState(null);

  const {
    useGetEncounter,
    useGetLatestEncounterByPatientId,
    useGetLatestEncounterByPatientIdencounterid,
    useGetEncountersByPatientId,
  } = useEncounter({
    patientId: 1,
  });

  const { useGetCommentCount } = useComment({ encounterId: 1 });
  const { useViewPatient } = usePatient();
  const [ViewPatient, { data, loading, error }] = useViewPatient;
  const [GetAllCommentsCount] = useGetCommentCount;

  const [GetEncounter] = useGetEncounter;
  const [GetLatestEncounterByPatientId] = useGetLatestEncounterByPatientId;
  const [GetLatestEncounterByPatientIdEncounterId] =
    useGetLatestEncounterByPatientIdencounterid;
  const params = useParams();
  const new_patient_form = util?.forms?.["register_patient"];
  const { getActionRbac } = useRbac();

  let patient_form;
  let patient_form_section;
  const [
    GetEncountersByPatientId,
    { data: encounterData, loading: encounterLoading, error: encouterError },
  ] = useGetEncountersByPatientId;

  if (acceptedPaths?.includes(urlWindow.pathname.split("/")[3])) {
    patient_form = new_patient_form?.sections
      ? {
          ...new_patient_form,
          sections: new_patient_form?.sections?.filter(
            (e) => e.key !== "demography"
          ),
        }
      : new_patient_form;
    patient_form_section =
      new_patient_form?.sections?.filter((e) => e.key !== "demography")?.[0]
        ?.key ?? "";
  } else if (urlWindow.pathname.split("/")[3] === "Edit") {
    patient_form = new_patient_form;
    patient_form_section = new_patient_form?.sections?.[0]?.key;
  } else {
    patient_form =
      urlWindow.pathname.split("/")[3] === "newVisit" ||
      encountedData?.["basicVisit_typeofvisit"] === "Follow Up Visit" ||
      !getActionRbac("view_patient", "view_pii") ||
      (parseInt(user?.role_id) === parseInt(ENV.medical_lead_role_id) &&
        encounterData?.GetEncountersByPatientId?.[0]?.self !== undefined &&
        !encounterData?.GetEncountersByPatientId?.[0]?.self)
        ? encountedData?.["basicVisit_typeofvisit"] === "Follow Up Visit"
          ? {
              ...new_patient_form,
              sections: new_patient_form?.sections?.filter(
                (e) =>
                  e.key !== "PPCI" &&
                  e.key !== "ClinicalExamination" &&
                  !(
                    encountedData?.["demography_gender"] === "Male" &&
                    e.key === "PregnancyHistory"
                  )
              ),
            }
          : {
              ...new_patient_form,
              sections: new_patient_form?.sections?.filter(
                (e) =>
                  e.key !== "PPCI" &&
                  !(
                    encountedData?.["demography_gender"] === "Male" &&
                    e.key === "PregnancyHistory"
                  )
              ),
            }
        : new_patient_form;
    patient_form_section =
      urlWindow.pathname.split("/")[3] === "newVisit" ||
      encountedData?.["basicVisit_typeofvisit"] === "Follow Up Visit" ||
      !getActionRbac("view_patient", "view_pii") ||
      (parseInt(user?.role_id) === parseInt(ENV.medical_lead_role_id) &&
        encounterData?.GetEncountersByPatientId?.[0]?.self !== undefined &&
        !encounterData?.GetEncountersByPatientId?.[0]?.self)
        ? encountedData?.["basicVisit_typeofvisit"] === "Follow Up Visit"
          ? new_patient_form?.sections?.filter(
              (e) =>
                e.key !== "PPCI" &&
                e.key !== "ClinicalExamination" &&
                !(
                  encountedData?.["demography_gender"] === "Male" &&
                  e.key === "PregnancyHistory"
                )
            )?.[0]?.key ?? ""
          : new_patient_form?.sections?.filter(
              (e) =>
                e.key !== "PPCI" &&
                !(
                  encountedData?.["demography_gender"] === "Male" &&
                  e.key === "PregnancyHistory"
                )
            )?.[0]?.key ?? ""
        : new_patient_form?.sections?.[0]?.key;
  }
  useEffect(() => {
    setUtil((old) => ({
      ...old,
      patient_form,
      patient_form_section,
    }));
  }, [new_patient_form]);

  const getInitialValue = (attributes, edit = {}) => {
    return attributes?.reduce((init, row) => {
      const { name } = row;
      return (init = {
        ...init,
        [name]:
          name !== "PPCI_nationalid"
            ? name === "demography_nationality"
              ? params.nationalId !== undefined
                ? params.nationalId.substring(0, 1) === "1"
                  ? "Saudi"
                  : "Non - Saudi"
                : ""
              : name === "demography_countrylist"
              ? params.nationalId !== undefined
                ? params.nationalId.substring(0, 1) === "1"
                  ? "Saudi Arabia"
                  : ""
                : ""
              : name === "PPCI_countrycode"
              ? "+966"
              : ""
            : params.nationalId !== undefined
            ? params.nationalId
            : "",
      });
    }, {});
  };
  // Custom validation for cascade value
  const customCascadeValidation = (value) => {
    if (
      value &&
      typeof value === "object" &&
      "cascadervalue" in value &&
      "value" in value
    ) {
      if (
        value.cascadervalue.length === 0 &&
        Object.keys(value.value).length === 0
      ) {
        return false;
      }
    }
    return true;
  };
  function isAtLeastOneNonChildSelected(options, selectedValues) {
    // Helper function to map values to their children status
    function buildValueMap(nodes) {
      const map = new Map();
      const parentMap = new Map();

      function traverse(node) {
        if (node.children && node.children.length > 0) {
          parentMap.set(
            node.value,
            node.children.map((child) => child.value)
          );
          node.children.forEach((child) => {
            traverse(child);
          });
        } else {
          map.set(node.value, true); // Mark leaf nodes
        }
      }

      nodes.forEach((node) => {
        traverse(node);
      });

      return { map, parentMap };
    }

    const { map: valueMap, parentMap } = buildValueMap(options);

    // Check if any selected value is in the valueMap and is a leaf node
    const hasNonChildSelected = selectedValues.some((value) =>
      valueMap.has(value)
    );

    // Check if any parent is selected without any of its children
    const hasParentWithoutChildrenSelected = selectedValues.some((value) => {
      if (parentMap.has(value)) {
        const children = parentMap.get(value);
        // Check if none of the children are selected
        return !children.some((child) => selectedValues.includes(child));
      }
      return false;
    });

    return hasNonChildSelected && !hasParentWithoutChildrenSelected;
  }

  function isAtLeastOneFieldNonEmpty(fieldValues) {
    return Object.values(fieldValues).some((value) => value.trim() !== "");
  }
  const generateValidationSchema = (attributes, recall = false, values) => {
    let subsection = {};
    const schemaShape = attributes?.reduce((schema, attribute) => {
      let {
        name,
        label,
        error,
        required,
        hidden,
        regex,
        options,
        dependentreverse,
        format,
        fieldValues,
        dependents,
        dependentValidation,
        fieldValueValidation,
        type,
        onlyBasic,
        checkVisitType,
        showonlybasline,
        dependentValidationarray,
        cascadeValidation,
        cascadevalidation,
        Accordionischecked,
      } = attribute;
      let validationRule = yup.string();

      if (regex) {
        validationRule = validationRule.matches(
          new RegExp(regex),
          `${label || name} is invalid`
        );
      }

      if (dependentValidationarray) {
        if (
          showonlybasline &&
          values?.["basicVisit_typeofvisit"] === "Baseline Visit"
        ) {
          if (
            checkAllEmpty(
              values,
              dependentValidationarray,
              name,
              type,
              cascadeValidation,
              options
            )
          ) {
            if (format === "array") {
              validationRule = yup
                .array()
                .of(yup.string())
                .required(`${label || name} is required`)
                .min(1);
            } else {
              validationRule = yup
                .string()
                .required(
                  error === "" ? `${label || name} is required` : error
                );
            }
          } else {
            validationRule = yup.string().nullable().optional();
          }
        }

        if (showonlybasline === undefined) {
          if (
            checkAllEmpty(
              values,
              dependentValidationarray,
              name,
              type,
              cascadeValidation,
              options
            )
          ) {
            if (format === "array") {
              validationRule = yup
                .array()
                .of(yup.string())
                .required(`${label || name} is required`)
                .min(1);
            } else {
              validationRule = yup
                .string()
                .required(
                  error === "" ? `${label || name} is required` : error
                );
            }
          } else {
            validationRule = yup.string().nullable().optional();
          }
        }
      } else {
        if (dependentValidation) {
          if (required) {
            if (Array.isArray(fieldValueValidation)) {
              validationRule = validationRule.when(
                dependentValidation,
                (value) => {
                  if (
                    fieldValueValidation.some((elems) =>
                      value[0]?.includes(elems)
                    )
                  ) {
                    if (format === "array") {
                      return yup
                        .array()
                        .of(yup.string())
                        .required(`${label || name} is required`)
                        .min(1);
                    } else {
                      return yup
                        .string()
                        .required(
                          error === "" ? `${label || name} is required` : error
                        );
                    }
                  } else {
                    return yup.string().nullable().optional();
                  }
                }
              );
            } else {
              if (cascadevalidation) {
                if (values?.[name] && type === "cascade" && cascadeValidation) {
                  let countValidation = [];
                  options.forEach((elems) => {
                    elems.children.forEach((ele) => {
                      if (ele.label === cascadeValidation) {
                        countValidation.push(`${ele.value} ->`);
                      }
                    });
                  });
                  let cascadeValidate = countValidation.every((str1) =>
                    JSON.parse(values[name]).cascadervalue.some((str2) =>
                      str2.includes(str1)
                    )
                  );
                  console.log("cascadeValidate", cascadeValidate);
                  if (values?.[dependentValidation] === fieldValueValidation) {
                    validationRule = validationRule
                      .required(
                        error === "" ? `${label || name} is required` : error
                      )
                      .test(
                        name,
                        error || `${label || name} is required`,
                        (value) => {
                          if (type === "cascade") {
                            if (
                              !cascadeValidate ||
                              value === '{"cascadervalue":[],"value":{}}'
                            ) {
                              return false;
                            }

                            try {
                              if (
                                isAtLeastOneNonChildSelected(
                                  options,
                                  JSON.parse(values?.[name]).cascadervalue
                                )
                              ) {
                                return true;
                              } else {
                                if (
                                  isAtLeastOneFieldNonEmpty(
                                    JSON.parse(values[name]).value
                                  )
                                ) {
                                  return true;
                                }
                                return false;
                              }
                            } catch (e) {
                              console.error("Invalid JSON format", e);
                              return;
                            }
                          }
                        }
                      );
                  } else {
                    validationRule = yup.string().nullable().optional();
                  }
                } else if (values?.[name] && type === "cascade") {
                  if (values?.[dependentValidation] === fieldValueValidation) {
                    console.log("cascadevalidation", cascadevalidation, name);
                    validationRule = validationRule
                      .required(
                        error === "" ? `${label || name} is required` : error
                      )
                      .test(
                        name,
                        error || `${label || name} is required`,
                        (value) => {
                          if (type === "cascade") {
                            if (value === '{"cascadervalue":[],"value":{}}') {
                              return false;
                            }

                            try {
                              if (
                                isAtLeastOneNonChildSelected(
                                  options,
                                  JSON.parse(values?.[name]).cascadervalue
                                )
                              ) {
                                return true;
                              } else {
                                if (
                                  isAtLeastOneFieldNonEmpty(
                                    JSON.parse(values[name]).value
                                  )
                                ) {
                                  return true;
                                }
                                return false;
                              }
                            } catch (e) {
                              console.error("Invalid JSON format", e);
                              return;
                            }
                          }
                        }
                      );
                  } else {
                    validationRule = yup.string().nullable().optional();
                  }
                } else {
                  if (values?.[dependentValidation] === fieldValueValidation) {
                    validationRule = yup
                      .string()
                      .required(
                        error === "" ? `${label || name} is required` : error
                      );
                  }
                }
              } else {
                validationRule = validationRule.when(
                  dependentValidation,
                  (value) => {
                    if (value?.[0] === fieldValueValidation) {
                      if (format === "array") {
                        return yup
                          .array()
                          .of(yup.string())
                          .required(`${label || name} is required`)
                          .min(1);
                      } else {
                        return yup
                          .string()
                          .required(
                            error === ""
                              ? `${label || name} is required`
                              : error
                          );
                      }
                    } else {
                      return yup.string().nullable().optional();
                    }
                  }
                );
              }
            }
          } else {
            if (Array.isArray(fieldValueValidation)) {
              validationRule = validationRule.when(
                dependentValidation,
                (value) => {
                  if (value[0]) {
                    if (
                      fieldValueValidation.some((elems) =>
                        value[0]?.includes(elems)
                      )
                    ) {
                      if (format === "array") {
                        return yup
                          .array()
                          .of(yup.string())
                          .required(`${label || name} is required`)
                          .min(1);
                      } else {
                        return yup
                          .string()
                          .required(
                            error === ""
                              ? `${label || name} is required`
                              : error
                          );
                      }
                    } else {
                      return yup.string().nullable().optional();
                    }
                  }
                }
              );
            } else {
              if (cascadevalidation) {
                if (values?.[name] && type === "cascade" && cascadeValidation) {
                  let countValidation = [];
                  options.forEach((elems) => {
                    elems.children.forEach((ele) => {
                      if (ele.label === cascadeValidation) {
                        countValidation.push(`${ele.value} ->`);
                      }
                    });
                  });
                  let cascadeValidate = countValidation.every((str1) =>
                    JSON.parse(values[name]).cascadervalue.some((str2) =>
                      str2.includes(str1)
                    )
                  );
                  if (values?.[dependentValidation] === fieldValueValidation) {
                    console.log("cascadevalidation", cascadevalidation, name);
                    validationRule = validationRule
                      .required(
                        error === "" ? `${label || name} is required` : error
                      )
                      .test(
                        name,
                        error || `${label || name} is required`,
                        (value) => {
                          if (type === "cascade") {
                            if (
                              !cascadeValidate ||
                              value === '{"cascadervalue":[],"value":{}}'
                            ) {
                              return false;
                            }

                            try {
                              if (
                                isAtLeastOneNonChildSelected(
                                  options,
                                  JSON.parse(values?.[name]).cascadervalue
                                )
                              ) {
                                return true;
                              } else {
                                if (
                                  isAtLeastOneFieldNonEmpty(
                                    JSON.parse(values[name]).value
                                  )
                                ) {
                                  return true;
                                }
                                return false;
                              }
                            } catch (e) {
                              console.error("Invalid JSON format", e);
                              return;
                            }
                          }
                        }
                      );
                  } else {
                    validationRule = yup.string().nullable().optional();
                  }
                } else if (type === "cascade") {
                  if (values?.[dependentValidation] === fieldValueValidation) {
                    console.log("cascadevalidation", cascadevalidation, name);
                    validationRule = validationRule
                      .required(
                        error === "" ? `${label || name} is required` : error
                      )
                      .test(
                        name,
                        error || `${label || name} is required`,
                        (value) => {
                          if (type === "cascade") {
                            if (value === '{"cascadervalue":[],"value":{}}') {
                              return false;
                            }

                            try {
                              if (
                                isAtLeastOneNonChildSelected(
                                  options,
                                  JSON.parse(values?.[name]).cascadervalue
                                )
                              ) {
                                return true;
                              } else {
                                if (
                                  isAtLeastOneFieldNonEmpty(
                                    JSON.parse(values[name]).value
                                  )
                                ) {
                                  return true;
                                }
                                return false;
                              }
                            } catch (e) {
                              console.error("Invalid JSON format", e);
                              return;
                            }
                          }
                        }
                      );
                  } else {
                    validationRule = yup.string().nullable().optional();
                  }
                }
              } else if (checkVisitType) {
                validationRule = validationRule.when(
                  checkVisitType,
                  (value, schema) => {
                    if (onlyBasic && value?.[0] === "Baseline Visit") {
                      return schema.when(
                        dependentValidation,
                        (values, schema) => {
                          const isFieldValid =
                            values?.[0] === fieldValueValidation;

                          return createValidationRule(
                            isFieldValid,
                            format,
                            label,
                            name,
                            error
                          );
                        }
                      );
                    }

                    if (!onlyBasic && value?.[0] === "Follow Up Visit") {
                      return schema.when(
                        dependentValidation,
                        (values, schema) => {
                          const isFieldValid =
                            values?.[0] === fieldValueValidation;

                          return createValidationRule(
                            isFieldValid,
                            format,
                            label,
                            name,
                            error
                          );
                        }
                      );
                    }

                    // If none of the specific visit types match, return the original schema
                    return schema;
                  }
                );
              } else {
                validationRule = validationRule.when(
                  dependentValidation,
                  (valuess, schema) => {
                    const isFieldValid = valuess?.[0] === fieldValueValidation;
                    if (
                      Accordionischecked &&
                      (values?.[
                        "ParaClinicalInvestigation_serologicaltests_numberoftest"
                      ] === undefined ||
                        values?.[
                          "ParaClinicalInvestigation_serologicaltests_numberoftest"
                        ] === "")
                    ) {
                      return yup.string().nullable().optional();
                    }
                    return createValidationRule(
                      isFieldValid,
                      format,
                      label,
                      name,
                      error
                    );
                  }
                );
              }
            }
          }
        } else {
          if (dependents) {
            validationRule = validationRule.when(dependents, (value) => {
              if (parseInt(fieldValues) <= parseInt(value?.[0])) {
                hidden = false;

                if (format === "array") {
                  return yup
                    .array()
                    .of(yup.string())
                    .required(`${label || name} is required`)
                    .min(1);
                } else {
                  return yup
                    .string()
                    .required(
                      error === "" ? `${label || name} is required` : error
                    );
                }
              } else {
                return yup.string().nullable().optional();
              }
            });
          } else {
            if (format) {
              switch (format) {
                case "number":
                  if (required) {
                    if (values?.[name] === undefined || values?.[name] === "") {
                      validationRule = validationRule.nullable().optional();
                    } else {
                      validationRule = validationRule
                        .required(
                          error === "" ? `${label || name} is required` : error
                        )
                        .test(
                          name,
                          error || `${label || name} is required`,
                          (value) => {
                            if (value.length > 3) {
                              if (
                                !isValidPhoneNumber(
                                  value,
                                  values?.["countryCode"]
                                )
                              ) {
                                return false;
                              }
                            }

                            return true; // Otherwise, pass validation
                          }
                        );
                    }
                  } else {
                    validationRule = validationRule.nullable().optional();
                  }

                  break;
                case "email":
                  // validationRule = validationRule.email();
                  validationRule = yup
                    .string()
                    .test("is-email", "Invalid email format", (value) => {
                      if (!value) return true; // Allow empty value
                      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
                    });
                  break;
                case "String":
                  if (required) {
                    validationRule = validationRule
                      .required(
                        error === "" ? `${label || name} is required` : error
                      )
                      .test(
                        name,
                        error || `${label || name} is required`,
                        (value) => {
                          if (type === "cascade") {
                            if (value === '{"cascadervalue":[],"value":{}}') {
                              return false;
                              // Trigger error if the value matches the specific condition
                            }

                            try {
                              if (
                                isAtLeastOneNonChildSelected(
                                  options,
                                  JSON.parse(values?.[name]).cascadervalue
                                )
                              ) {
                                return true;
                              } else {
                                if (
                                  isAtLeastOneFieldNonEmpty(
                                    JSON.parse(values[name]).value
                                  )
                                ) {
                                  return true;
                                }
                                return false;
                              }
                            } catch (e) {
                              console.error("Invalid JSON format", e);
                              return;
                            }
                          }

                          return true; // Otherwise, pass validation
                        }
                      );
                  } else {
                    validationRule = validationRule.nullable().optional();
                  }
                  break;
                case "date":
                  if (required) {
                    validationRule = yup
                      .date()
                      .required(`${label || name} is required`)
                      .nullable();
                  }
                  break;
                case "array":
                  if (required) {
                    validationRule = yup
                      .array()
                      .of(yup.string())
                      .required(`${label || name} is required`)
                      .min(1);
                  }
                  // Elements of the array should be string
                  break;

                case "boolean":
                  if (required) {
                    validationRule = yup
                      .boolean()
                      .test(
                        name,
                        error === "" ? `${label || name} is required` : error,
                        (value) => {
                          return value === true;
                        }
                      );
                  }
                  break;
                case "object":
                  if (required) {
                    validationRule = validationRule.required(
                      error === "" ? `${label || name} is required` : error
                    );
                  }
                  break;

                default:
                  break;
              }
            }
          }
        }
      }

      let sub1 = {};
      if (type === "MenuList") {
        sub1 = generateValidationSchema(options[0].attribute, true, values);
      }
      let sub2 = {};
      if (type === "Accordion") {
        sub2 = generateValidationSchema(options, true, values);
      }

      return { ...schema, [name]: validationRule, ...sub1, ...sub2 };
    }, {});
    if (recall) return { ...schemaShape };
    else return yup.object().shape({ ...schemaShape });
  };
  const isValidPhoneNumber = (phoneNumber, countryCode) => {
    phoneNumber = `+${phoneNumber}`;
    try {
      // Parse and validate the phone number
      const parsedNumber = parsePhoneNumber(
        phoneNumber,
        countryCode.toUpperCase()
      );
      if (parsedNumber.isValid()) {
        return true;
      } else {
        return false;
      }
    } catch (error) {}
  };

  const createValidationRule = (isFieldValid, format, label, name, error) => {
    if (isFieldValid) {
      if (format === "array") {
        return yup
          .array()
          .of(yup.string())
          .required(`${label || name} is required`)
          .min(1);
      } else {
        return yup
          .string()
          .required(error === "" ? `${label || name} is required` : error);
      }
    } else {
      return yup.string().nullable().optional();
    }
  };
  function checkAllEmpty(obj, fields, name, type, cascadeValidation, options) {
    let cascadevalidate = false;
    let dependentValidationarray2 = [];
    if (obj?.["basicVisit_typeofvisit"] === "Follow Up Visit") {
      dependentValidationarray2 = [
        "TreatmentInterventionCurrentMedication_None_isChecked",
        "TreatmentIntervention_acetylcholinesteraseinhibitorsCurrentMedication_isChecked",
        "TreatmentIntervention_corticosteroidstreatmentCurrentMedication_isChecked",
        "TreatmentIntervention_CyclicintravenousimmunoglobulinsIVIGCurrentMedication_isChecked",
        "TreatmentIntervention_CyclicPlasmapheresisPLEXCurrentMedication_isChecked",
        "TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_isChecked",
        "TreatmentIntervention_BoneMarrowTransplant(BMT)CurrentMedication_isChecked",
        "TreatmentIntervention_corticosteroidstreatmentCurrentMedicationothers_isChecked",
      ];
    } else {
      dependentValidationarray2 = [
        "TreatmentInterventionCurrentMedication_None_isChecked",
        "TreatmentIntervention_acetylcholinesteraseinhibitorsCurrentMedication_isChecked",
        "TreatmentIntervention_corticosteroidstreatmentCurrentMedication_isChecked",
        "TreatmentIntervention_CyclicintravenousimmunoglobulinsIVIGCurrentMedication_isChecked",
        "TreatmentIntervention_CyclicPlasmapheresisPLEXCurrentMedication_isChecked",
        "TreatmentIntervention_ImmunosuppressiveTreatmentCurrentMedication_isChecked",
        "TreatmentIntervention_corticosteroidstreatmentCurrentMedicationothers_isChecked",
      ];
    }
    const dependentValidationarray1 = [
      "TreatmentIntervention_PreviousNone_isChecked",
      "TreatmentIntervention_CyclicintravenousimmunoglobulinsIVIG_isChecked",
      "TreatmentIntervention_corticosteroidstreatment_isChecked",
      "TreatmentIntervention_acetylcholinesteraseinhibitors_isChecked",
      "TreatmentIntervention_CyclicPlasmapheresisPLEXCurrentMedication_isChecked",
      "TreatmentIntervention_BoneMarrowTransplant(BMT)_isChecked",
      "TreatmentIntervention_rapidimmunotherapies_isChecked",
      "TreatmentIntervention_ImmunosuppressiveTreatment_isChecked",
      "TreatmentIntervention_others_isChecked",
    ];
    const dependentValidationarray4 = [
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_frustratedmg",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubleeyes",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubleeating",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_socialactivity",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_enjoyhobbies",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_needsoffamily",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_makeplans",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_occupationalskills",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_difficultyspeaking",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubledriving",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_depressed",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troublewalking",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troublepublicplaces",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_feeloverwhelmed",
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_troubleperformingpersonal",
    ];

    const dependentValidationarray3 = [
      "Diagnosis_mgseverityclassificationon_unknown_isChecked",
      "Diagnosis_mgseverityclassificationon_MGFA_isChecked",
    ];

    const dependentValidationarray5 = [
      "ClinicalAssessment_myastheniagravisqualityoflifeitemscale_totalscore",
    ];

    // Check if obj is defined and is an object
    if (obj === undefined || typeof obj !== "object" || obj === null) {
      return false; // or handle as per your requirement
    }
    let validKeys;
    let validKeyssecond;
    // Filter out keys that are not present in obj
    if (fields === 1) {
      validKeys = dependentValidationarray1.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 2) {
      validKeys = dependentValidationarray2.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 3) {
      validKeys = dependentValidationarray3.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 4) {
      validKeys = dependentValidationarray4.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 4) {
      validKeyssecond = dependentValidationarray5.filter((key) =>
        obj.hasOwnProperty(key)
      );
    }
    if (fields === 4) {
      const values = validKeys?.map((key) => obj[key]);

      let allNotEmpty =
        Array.isArray(values) &&
        values.every(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            value === "false" ||
            value === false ||
            value === '{"cascadervalue":[],"value":{}}'
        );
      if (fields === 4) {
        if (allNotEmpty) {
          const valuess = validKeyssecond.map((key) => obj[key]);

          let allEmptys =
            Array.isArray(valuess) &&
            valuess.every(
              (value) => value !== "" && value !== null && value !== undefined
            );

          if (!allEmptys) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    } else {
      const values = validKeys?.map((key) => obj?.[key]);
      let allEmpty =
        Array.isArray(values) &&
        values.every(
          (value) =>
            value === "" ||
            value === null ||
            value === undefined ||
            value === "false" ||
            value === false ||
            value === '{"cascadervalue":[],"value":{}}'
        );

      if (allEmpty) {
        return true;
      } else {
        return false;
      }
    }
  }

  const handleKeyDown = (event, allowedKeyss) => {
    // Allowed keycodes for N, D, and S
    const allowedKeys = allowedKeyss; // 78: N, 68: D, 83: S

    // Check if the pressed key is in the allowed keys array
    if (!allowedKeys.includes(event.keyCode)) {
      event.preventDefault(); // Prevent default action if the key is not allowed
    }
  };

  const getSelectedSection = () => {
    return util?.patient_form_section;
  };
  const setSelectedSection = (section) => {
    setUtil((old) => ({ ...old, patient_form_section: section }));
  };

  return {
    patient_form: util?.patient_form,
    getValidationSchema: generateValidationSchema,
    setSelectedSection: setSelectedSection,
    getSelectedSection: getSelectedSection,
    getInitialValue: getInitialValue,
    encountedData: encountedData,
    encountedDataLatestStatus: encountedDataLatestStatus,
    setEncountedDataLatestStatus: setEncountedDataLatestStatus,
    patient_selected_section: util?.patient_form_section,
    commentData: commentData,
    setCommentData: setCommentData,
    encounterData: encounterData,
    pageLoad: pageLoad,
    encountedDatamain: encountedDatamain,
    patientActive: patientActive,
    patientData: patientData,
    setCrfError: setError,
    crfErrors: util?.crfErrors ?? {},
    setCrfTouch: setTouch,
    crfTouch: util?.crfTouch ?? {},
  };
};

export const postProcessData = (data) => {
  const processedData = {};
  for (let keys in data) {
    if (data.hasOwnProperty(keys)) {
      const parts = keys.split("_");
      let currentObj = processedData;

      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        if (i === parts.length - 1) {
          // Last part, assign the value
          if (Array.isArray(data[keys])) {
            currentObj[part] = [...data[keys]]; // Clone the array
          } else if (typeof data[keys] === "object" && data[keys] !== null) {
            currentObj[part] = { ...data[keys] }; // Clone the object
          } else {
            currentObj[part] = data[keys]; // Assign primitive or non-object value
          }
        } else {
          // Ensure currentObj[part] is an object or array, based on the next part
          if (
            typeof currentObj[part] !== "object" ||
            currentObj[part] === null
          ) {
            currentObj[part] = {}; // Default to object
          }
          currentObj = currentObj[part];
        }
      }
    }
  }
  return processedData;
};

export const preProcessData = (data, prefix = "") => {
  const processedData = {};

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const newKey = prefix ? `${prefix}_${key}` : key;

      if (
        typeof data[key] === "object" &&
        data[key] !== null &&
        !Array.isArray(data[key])
      ) {
        // Recursively process nested objects
        Object.assign(processedData, preProcessData(data[key], newKey));
      } else {
        // Directly assign primitive types, arrays, and null
        processedData[newKey] = data[key];
      }
    }
  }

  return processedData;
};
